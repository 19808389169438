.clients-draft-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-size: 1em;

	.modal-body {
		overflow: auto;
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.section-header-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.section-accordion-btn {
				font-size: 0.9em;
				color: #9d9d9d;
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.section-header {
			font-size: 1.2em;
			font-weight: 600;
			color: #272727;
		}

		.section-description {
			font-size: 1em;
			font-weight: 400;
			color: #9d9d9d;
		}

		.client-filters-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.client-filter-container {
				display: flex;
				align-items: center;
				gap: 8px;
				flex-wrap: wrap;

				.client-filter-container-inner {
					padding: 4px 8px;
					border: 1px solid #d8d8d8;
					background-color: #f8f8fc;
					color: #272727;
					border-radius: 4px;
				}
			}
		}

		.divider {
			width: 50%;
			min-height: 4px;
			height: 4px;
			background-color: #523563;
		}

		.client-draft-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.client-container {
				display: flex;
				gap: 16px;
				align-items: center;

				.client-select {
					width: 12px;
					height: 12px;
					border: 1px solid #d8d8d8;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					&.selected {
						border-color: #46be46;
					}

					&.disabled {
						visibility: hidden;
					}
				}

				.select-btn {
					cursor: pointer;
					padding: 4px 0;
					font-size: 1em;
					margin-top: 2px;

					&:hover {
						color: #3498DB;
					}
				}

				.client-container-inner {
					flex: 1;
					cursor: pointer;
					background-color: #f8f8fc;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					padding: 8px 12px;

					&:hover {
						background-color: #f1f1fc;
					}

					.bill-details {
						display: flex;
						flex-direction: column;
						gap: 4px;
						justify-content: flex-end;
						font-size: 1em;
						color: #272727;

						.client-maintext {
							font-size: 1em;
							font-weight: 600;
							color: #272727;
						}

						.client-subtext {
							display: flex;
							gap: 4px;
							align-items: center;
							font-size: 0.9em;
							color: #9d9d9d;
							margin-top: 4px;
						}

					}
				}
			}
		}
	}
}