.screen-wrapper {
	display: flex;
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;

	main.main-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;
		min-height: 100%;
		width: 100%;
		background-color: #FCFCFF;
		overflow: hidden;

		.component-wrapper {
			overflow-y: auto;
			flex: 1;
			margin-top: -16px;
			padding-left: 88px;
			background-color: #FCFCFF;
			border-top-left-radius: 16px;
			border-top-right-radius: 16px;
		}
	}
}

.sidebar-container {
	background: #1c1222;
	transition: all 200ms;
	position: absolute;
	left: 8px;
	top: 56px;
	z-index: 10;
	height: calc(100vh - 64px);
	border-radius: 16px;

	.sidebar-container-inner {
		display: flex;
		flex-direction: column;
		padding: 0 16px;
		justify-content: space-between;
		height: 100%;

		@media screen and (max-height: 699px) {
			overflow-y: auto;
		}

		&::-webkit-scrollbar {
			display: none;
		}

	}

	.sidebar-toggler {
		position: absolute;
		cursor: pointer;
		top: 4px;
		right: -12px;
		background-color: #f8f8fc;
		color: #523563;
		font-size: 12px;
		border: 2px solid #1c1222;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		&.toggle-rotate {
			transform: rotate(180deg);
		}
	}

	.sidebar-nav-links {
		display: flex;
		flex-direction: column;
		gap: 4px;
		padding: 16px 0;

		.nav-link {
			cursor: pointer;
			text-decoration: none;
			display: flex;
			align-items: center;
			border-radius: 4px;
			color: #9d9d9d;
			padding: 12px 16px;
			gap: 16px;
			font-size: 14px;

			&.active {
				background-color: #f8f8fc;
				color: #523563;
			}

			&:hover {
				background-color: #f8f8fc;
				color: #523563;
			}

			@media screen and (min-height: 700px) {
				&.tooltip-visible {
					position: relative;

					&:after,
					&:before {
						opacity: 0;
						pointer-events: none;
						bottom: 100%;
						left: 50%;
						position: absolute;
						z-index: 10;
						transform: translate(-50%, 10px);
						transform-origin: top;
						transition: all 0.18s ease-out 0.18s;
					}

					&:after {
						background-color: #523563;
						border-radius: 8px;
						box-shadow: 0 0 3px #523563;
						content: attr(data-tooltip);
						padding: 10px;
						white-space: nowrap;
						margin-bottom: 11px;
						color: #f8f8fc;
					}

					&:before {
						width: 0;
						height: 0;
						content: "";
						margin-bottom: 6px;
					}

					&:hover:before,
					&:hover:after {
						opacity: 1;
						pointer-events: auto;
						transform: translate(-50%, 0);
					}

					&[data-tooltip-pos="right"]:before,
					&[data-tooltip-pos="right"]:after {
						bottom: auto;
						left: 100%;
						top: 50%;
						transform: translate(-10px, -50%);
					}

					&[data-tooltip-pos="right"]:after {
						margin-left: 11px;
					}

					&[data-tooltip-pos="right"]:hover:before,
					&[data-tooltip-pos="right"]:hover:after {
						transform: translate(0, -50%);
					}

					&[data-tooltip-length="medium"]:after {
						padding-left: 24px;
						padding-right: 24px;
					}
				}
			}
		}

		&.link-gap {
			margin-bottom: 24px;
		}

		.link-icon {
			min-width: 20px;
			font-size: 13px;
		}

		.link-text {
			transform-origin: left;
			transition-duration: 200ms;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.text-hidden {
				transform: scale(0);
			}
		}
	}
}

.sidebar-bottom {
	margin-bottom: 16px;
}

.additional-container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 32px 0;

	.additional-dark-text {
		font-size: 18px;
		font-weight: 600;
		color: #d8d8d8;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;

		img {
			width: 32px;
			aspect-ratio: 1/1;
			object-fit: contain;
			margin-left: 8px;
		}

		span {
			transform-origin: left;
			transition-duration: 250ms;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&.text-hidden {
				transform: scale(0);
			}
		}
	}

	.additional-light-text {
		font-size: 10px;
		font-weight: 400;
		color: #9d9d9d;
		line-height: 1.5;
		margin: 0 4px;
	}
}


@media screen and (max-width: 1440px) {
	.sidebar-container .nav-link {
		font-size: 12px;
	}
}

// @media screen and (max-width: 1024px) {
// 	.sidebar-container {
// 		display: none;
// 	}
// }