// Swal(sweetalert2) custom style for alert modal
.alert-modal {
	font-size: 12px;
	color: #272727;

	.btn-container {
		width: 100%;
		padding: 0 1.25em;
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-start;

		.alert-btn {
			height: 32px;
			color: #f8f8fc;
			outline: 0;
			transition: all 200ms ease-in-out;

			&:hover {
				transform: scale(0.95);
			}

			&:focus {
				box-shadow: none;
			}

			&.btn-confirm {
				background-color: #3498db;
			}

			&.btn-cancel {
				background-color: #b53e2c;
			}
		}
	}
}

.App-no-mobile-view {
	display: none;
}

@media only screen and (max-width: 680px) {
	.App {
		display: none;
	}

	.App-no-mobile-view {
		background: rgb(82, 53, 99);
		background: linear-gradient(213deg, rgba(82, 53, 99, 1) 0%, rgba(28, 18, 34, 1) 100%);
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 24px;
		font-size: 16px;
		color: #e7e7e7;

		img {
			width: 80px;
			object-fit: contain;
			aspect-ratio: 1/1;
		}
	}
}