.empty-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	padding-top: 32px;
	gap: 24px;
	font-size: 1em;

	img {
		width: 120px;
		object-fit: contain;
	}

	.empty-message {
		font-size: 1.32em;
		color: #523563;
		font-weight: 600;
	}
}