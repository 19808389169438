.dashboard-wrapper {
	padding: 48px;
	font-size: 12px;
	overflow-x: hidden;

	.dashboard-container {
		display: flex;
		flex-direction: column;
		gap: 24px;
		height: 100%;

		.dashboard-header {
			display: flex;
			align-items: center;
			gap: 16px;

			.header-image {
				img {
					width: 300px;
					object-fit: contain;
				}
			}

			.header-description {
				display: flex;
				flex-direction: column;
				gap: 8px;
				max-width: 500px;

				.header-text-dark {
					font-weight: 400;
					font-size: 1em;
					color: #272727;

					&.text-xl {
						color: #272727;
						font-size: 2em;
						font-weight: 600;
					}
				}

				.header-text-light {
					margin-top: 4px;
					font-weight: 400;
					color: #9d9d9d;
					font-size: 1em;
					// ellipsis at specified line
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 3;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.create-invoice-btn {
					align-self: flex-start;
					margin: 8px 0;
					padding: 16px;
					height: 32px;
					border-radius: 4px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #523563;
					color: #f8f8fc;
					cursor: pointer;
					transition: all 250ms ease-in-out;

					&:hover {
						transform: scale(0.95);
					}
				}
			}
		}

		.wrapper-title {
			font-size: 1.1em;
			color: #272727;
			font-weight: 600;
			margin-bottom: 8px;
		}

		.wrapper-action {
			align-self: flex-end;
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			font-size: 1.1em;
			color: #3498db;
			transition: all 350ms ease-in-out;
			margin-top: 8px;

			&:hover {
				transform: scale(0.9);
			}

			i {
				font-size: 0.9em;
			}
		}

		.dashboard-summary-container {
			display: flex;
			gap: 24px;

			.flex-item-container {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 24px;

				&.flex-3 {
					flex: 3;
				}

				// invoice bill summary
				.invoice-summary-wrapper {
					display: flex;
					gap: 24px;
					flex-wrap: wrap;

					.invoice-summary-container {
						min-height: 132px;
						min-width: 178px;
						flex: 1;
						background: #f8f8fc;
						border-radius: 8px;
						padding: 16px;
						box-shadow: 0px 0px 4px #dddddd;
						display: flex;
						flex-direction: column;
						gap: 8px;

						.summary-highlight {
							display: flex;
							align-items: center;
							gap: 8px;
							margin-bottom: 8px;

							.summary-icon {
								width: 36px;
								height: 36px;
								border-radius: 4px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 1.4em;
							}

							.summary-info {
								font-size: 1.5em;
								font-weight: 600;

								span {
									font-size: 0.6em;
								}
							}

							&.bill {
								.summary-icon {
									background-color: #3498db22;
									color: #3498db;
								}

								.summary-info {
									color: #3498db;
								}
							}

							&.credit {
								.summary-icon {
									background-color: #d35a4722;
									color: #d35a47;
								}

								.summary-info {
									color: #d35a47;
								}
							}

							&.amount {
								.summary-icon {
									background-color: #46be4622;
									color: #46be46;
								}

								.summary-info {
									color: #46be46;
								}
							}
						}

						.summary-title {
							font-size: 1em;
							font-weight: 600;
							color: #272727;
						}

						.summary-description {
							font-size: 0.9em;
							font-weight: 400;
							color: #9d9d9d;
							// ellipsis at specified line
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.summary-wrapper {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					gap: 24px;
					background: #f8f8fc;
					border-radius: 8px;
					padding: 24px;
					box-shadow: 0px 0px 4px #dddddd;

					&.flex-2 {
						flex: 2;
					}

					&.bg-dark {
						background-color: #523563;
					}

					// draft summary
					.draft-status-container {
						display: flex;
						flex-direction: column;
						gap: 8px;

						.status-info {
							display: flex;
							justify-content: space-between;
							align-items: center;

							h5.status-title {
								color: #272727;
								font-size: 1.2em;
								font-weight: 600;
							}

							p.status-percent {
								font-size: 1.1em;
								font-weight: 600;
							}
						}

						.status-line {
							width: 100%;
							height: 6px;
							border-radius: 4px;
							overflow: hidden;

							.line {
								height: 6px;
								border-radius: 4px;
							}
						}

						p.status-description {
							color: #9d9d9d;
							font-size: 1em;
							font-weight: 400;
							// ellipsis at specified line
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;

							span {
								font-size: 1.2em;
							}
						}

						&.draft {
							.status-info p.status-percent {
								color: #ff8774;
							}

							.status-line {
								color: #ff8774;
								background-color: #ff877422;

								.line {
									background-color: #ff8774;
								}
							}

							p.status-description span {
								color: #ff8774;
							}
						}

						&.ready {
							.status-info p.status-percent {
								color: #3498db;
							}

							.status-line {
								color: #3498db;
								background-color: #3498db22;

								.line {
									background-color: #3498db;
								}
							}

							p.status-description span {
								color: #3498db;
							}
						}

						&.failed {
							.status-info p.status-percent {
								color: #b53e2c;
							}

							.status-line {
								color: #b53e2c;
								background-color: #b53e2c22;

								.line {
									background-color: #b53e2c;
								}
							}

							p.status-description span {
								color: #b53e2c;
							}
						}
					}

					// revenue summary
					.revenue-wrapper-header {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 8px;

						.revenue-detail {
							font-size: 2em;
							color: #3498db;
							font-weight: 600;

							span {
								font-size: 0.6em;
							}
						}
					}

					.revenue-chart-container {
						flex: 1;
						padding: 16px 0;
						min-height: 400px;
						position: relative;
					}

					// clients summary
					.client-details {
						flex: 1;
						display: flex;
						flex-direction: column;
						gap: 16px;
						align-items: center;
						justify-content: center;

						.client-icon {
							display: flex;
							align-items: center;
							justify-content: center;
							width: 42px;
							height: 42px;
							border-radius: 4px;
							background-color: #f8f8fc;
							font-size: 2em;
							color: #523563;
						}

						.client-label {
							font-size: 1.2em;
							color: #d8d8d8;
							font-weight: 400;
						}

						.client-volume {
							font-size: 3em;
							font-weight: 600;
							color: #f8f8fc;
						}
					}
				}
			}
		}

		.dashboard-table-container {
			display: flex;
			gap: 24px;

			.table-wrapper {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 24px;
				background: #f8f8fc;
				border-radius: 8px;
				padding: 24px;
				box-shadow: 0px 0px 4px #dddddd;

				.activities-wrapper {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 8px;

				}

				.bills-table-container {
					flex: 1;
					display: flex;
					flex-direction: column;

					.table-grid-container {
						flex: 1;
						display: grid;
						grid-template-columns: repeat(4, 1fr);
						padding: 12px;
						gap: 8px;

						&.header {
							border-top: 1px solid #D8D8D855;
							border-bottom: 1px solid #D8D8D855;
							background-color: #D8D8D855;
							margin-bottom: 8px;
						}

						.table-item-text {
							display: flex;
							flex-direction: column;
							align-items: center;
							gap: 4px;
							color: #272727;
							font-size: 1em;
							white-space: nowrap;
							text-transform: capitalize;

							&.copy {
								cursor: copy;
							}

							&.align-right {
								align-items: flex-end;
							}

							&.align-left {
								align-items: flex-start;
							}

							&>span {
								color: #9d9d9d;
							}

							.bill-price {
								font-size: 1.32em;
								color: #46be46;
								font-weight: 600;

								&.void {
									color: #d35a47;
								}

								&>b {
									font-size: 0.8em;
								}
							}
						}
					}
				}
			}
		}
	}

}

@media screen and (max-width: 1024px) {
	.dashboard-wrapper {
		font-size: 11px;
		padding: 32px;

		.dashboard-container {

			.dashboard-summary-container {
				flex-direction: column;

				.flex-item-container.flex-row {
					flex-direction: row;
				}
			}

			.dashboard-table-container {
				flex-direction: column;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.dashboard-wrapper {
		font-size: 10px;
		padding: 24px;
	}
}