.input-label {
	color: #9d9d9d;
	font-size: 1em;
	font-weight: 400;
	margin-bottom: 4px;
	white-space: nowrap;
}

.input-row {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
	align-items: flex-start;
}

.input-item {
	width: 100%;
	flex: 1;
	color: #9d9d9d;
	display: flex;
	flex-direction: column;

	.input-box-wrapper {
		width: 100%;
		min-width: 72px;
		font-size: 1em;
		border: 0;
		outline: 0;
		border: 1px solid #d8d8d866;
		background-color: #f1f1fc;
		height: 32px;
		border-radius: 4px;
		padding: 0 8px;
		color: #272727;
		display: flex;
		align-items: center;

		&:disabled,
		&.disabled {
			background-color: transparent;

			&::placeholder {
				opacity: 0;
			}
		}
	}

	.textarea-wrapper {
		padding: 8px;
		width: 100%;
		max-width: 100%;
		font-size: 1em;
		border: 0;
		outline: 0;
		border: 1px solid #d8d8d8;
		background-color: #f1f1fc;
		border-radius: 4px;
		resize: vertical;
	}

	.input-pswrd-wrapper {
		display: flex;
		align-items: center;
		font-size: 1em;
		// width: 285px;
		width: 100%;
		height: 32px;
		border: 1px solid #d8d8d866;
		background-color: #f1f1fc;
		border-radius: 4px;

		&.disabled {
			background-color: transparent;

			&::placeholder {
				opacity: 0;
			}
		}

		.input-pswrd-container {
			height: 100%;
			background-color: transparent;
			flex: 1;
			border: 0;
			outline: 0;
			padding: 0 8px;
			color: #272727;
			font-size: 1em;

			&::placeholder {
				color: #9d9d9d;
			}
		}

		.input-action {
			padding: 0 8px;
			font-size: 12px;
			color: #d8d8d8;
			cursor: pointer;
			display: flex;
			align-items: center;
			height: 100%;
		}
	}

	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.validation-error {
		color: #d35a47;
		font-size: 9px;
	}
}

// ck editor
.ck-toolbar {
	border: 1px solid #d8d8d866 !important;
}

.ck-editor__editable_inline {
	min-height: 180px;
	padding: 0 16px !important;

	&:not(.ck-focused) {
		border: 1px solid #d8d8d866 !important;
	}
}

// multiple tags input
.tags-wrapper {
	display: flex;
	align-items: center;
	gap: 8px;
	flex-wrap: wrap;
	padding-top: 8px;

	.tag-container {
		background-color: #f1f1fc;
		border: 1px solid #e7e7e7;
		display: flex;
		align-items: center;
		padding: 4px 6px;
		border-radius: 20px;

		.tag-item {
			font-size: 1em;
			color: #272727;
			padding: 0 4px;
		}

		.tag-close {
			min-height: 16px;
			max-height: 16px;
			min-width: 16px;
			max-width: 16px;
			background-color: #272727;
			color: #fff;
			border-radius: 50%;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			transition: all 200ms ease-in-out;
			cursor: pointer;

			&:hover {
				font-size: 14px;
			}
		}
	}
}

// date input
.input-date-wrapper {
	width: 100%;
	display: flex;
	gap: 8px;

	.date-type-options {
		min-width: 64px;
		display: flex;
		align-items: center;
		border: 1px solid #d8d8d866;
		border-radius: 4px;
		overflow: hidden;

		.type-btn {
			height: 100%;
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.8em;
			color: #272727;
			cursor: pointer;
			transition: all 200ms ease-in-out;

			&:hover {
				background-color: #f1f1fc;

				&.en {
					background-color: #1476b7;
				}

				&.np {
					background-color: #9c3222;
				}
			}

			&.en {
				color: #f8f8fc;
				background-color: #3498db;
			}

			&.np {
				color: #f8f8fc;
				background-color: #d35a47;
			}

		}
	}
}

// switch on/off input
.switch-container {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;

	input {
		opacity: 0;
		width: 0;
		height: 0;

		&:checked+.switch-slider {
			background-color: #3498db;

			&::before {
				transform: translateX(20px);
			}
		}
	}

	.switch-slider {
		position: absolute;
		cursor: pointer;

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: #d8d8d8;
		border-radius: 20px;
		transition: all 400ms ease-in-out;

		&::before {
			position: absolute;
			content: "";

			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: #f8f8fc;

			top: 3px;
			left: 3px;

			transition: all 400ms ease-in-out;
		}
	}
}

// select input
.select-wrapper {
	border: 1px solid #d8d8d866;
	border-radius: 4px;
	padding: 0 8px;
	background-color: #f1f1fc;

	&.disabled {
		background-color: transparent;
	}

	.select-box-wrapper {
		min-width: 120px;
		font-size: 1em;
		cursor: pointer;
		border: 0;
		outline: 0;
		background-color: transparent;
		width: 100%;
		height: 32px;
		color: #272727;
	}

	.validation-error {
		color: #d35a47;
		font-size: 9px;
	}
}