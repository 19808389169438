.create-invoice-wrapper {
	height: 100%;
	display: flex;
	box-sizing: border-box;
	padding: 48px;
	font-size: 12px;
	overflow-x: auto;

	.section-title-text {
		font-size: 1.6em;
		font-weight: 600;
		color: #272727;
		text-transform: capitalize;
	}

	.draft-form-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 8px;
		min-height: 32px;

		.form-header-subtext {
			position: relative;
			background-color: #f8f8fc;
			border: 1px solid #d8d8d8;
			height: 40px;
			border-radius: 4px;
			padding: 0 8px;
			display: flex;
			align-items: flex-end;

			.label-info {
				color: #272727;
				font-size: 1em;
				font-weight: 600;
				padding-bottom: 4px;
			}

			.label {
				color: #9d9d9d;
				font-size: 1em;
				font-weight: 400;
				position: absolute;
				top: -20%;
				background-color: #f8f8fc;
				padding: 0 4px;
			}
		}
	}

	.header-divider {
		height: 4px;
		min-height: 4px;
		border-radius: 4px;
		width: 40%;
		background-color: #523563;
	}

	.draft-form-container {
		flex: 2;
		min-width: 380px;
		padding: 16px;
		margin: 16px;
		overflow-y: auto;
		border-radius: 4px;
		box-shadow: 0px 0px 4px #dddddd;
	}

	.empty-container {
		color: #272727;
		text-align: center;
		padding: 16px;
		font-size: 1.2em;
	}
}

@media screen and (max-width: 1580px) {
	.create-invoice-wrapper {
		font-size: 11px;
		padding: 32px;
	}
}

@media screen and (max-width: 1290px) {
	.create-invoice-wrapper {
		font-size: 10px;
		padding: 24px;
	}
}

@media screen and (max-width: 800px) {
	.activity-monitor-wrapper {
		font-size: 9px;
		padding: 20px;
	}
}