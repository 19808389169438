.create-invoice-modal {
	display: flex;
	flex-direction: column;
	height: 100%;

	.modal-body {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		padding: 16px;

		&::-webkit-scrollbar {
			display: none;
		}

		.bill-add-response {
			padding: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			height: 45px;
			border-radius: 4px;
			position: sticky;
			top: -14px;
			z-index: 1;

			&.success {
				background-color: #D7ECD9;
				color: #46BE46;
			}

			&.failed {
				background-color: #EBD3D3;
				color: #B53E2C;
			}

			&.warn {
				background-color: #FAE1E1;
				color: #FF8774;
			}

			.response-icon {
				font-size: 1.4em;
			}

			.response-message {
				font-size: 1.1em;
				font-weight: 400;
			}

			.response-action-btn {
				white-space: nowrap;
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 8px 16px;
				cursor: pointer;
				font-size: 1.1em;
				border-radius: 4px;
				transition: all 200ms ease-in-out;
				text-decoration: underline;

				i {
					font-size: 0.9em;
					margin-bottom: 2px;
				}

				&:hover {
					box-shadow: none;
					transform: scale(0.95);
				}

				&[disabled] {
					cursor: none;
					pointer-events: none;
					box-shadow: none;
				}
			}
		}

		.invoice-form-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;

			.form-header-text {
				font-size: 1.6em;
				font-weight: 600;
				color: #272727;
				text-transform: capitalize;
			}

			.form-header-subtext {
				position: relative;
				background-color: #f8f8fc;
				border: 1px solid #d8d8d8;
				height: 40px;
				border-radius: 4px;
				padding: 0 8px;
				display: flex;
				align-items: flex-end;

				.label-info {
					color: #272727;
					font-size: 1em;
					font-weight: 600;
					padding-bottom: 4px;
				}

				.label {
					color: #9d9d9d;
					font-size: 1em;
					font-weight: 400;
					position: absolute;
					top: -20%;
					background-color: #f8f8fc;
					padding: 0 4px;
				}
			}
		}

		.header-divider {
			height: 4px;
			min-height: 4px;
			border-radius: 4px;
			width: 40%;
			background-color: #523563;
		}

		.modal-template-wrapper {

			.modal-template-container {
				position: relative;
				width: 673.6px;
				height: 952px;
				margin: 0 auto;

				.modal-template-container-inner {
					background-color: #f8f8fc;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(0.8);
					font-size: 12px;
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.create-invoice-modal .modal-body .modal-template-wrapper .modal-template-container {
		width: 505.2px;
		height: 714px;

		.modal-template-container-inner {
			transform: translate(-50%, -50%) scale(0.6);
		}
	}
}