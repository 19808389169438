.bill-list-container {
	flex: 2 0 320px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	margin: 8px;

	.bill-section-header {
		margin: 8px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 24px;
		background-color: #f8f8fc;
		border-radius: 4px;
		box-shadow: 0px 0px 4px #dddddd;

		.header-title {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			h2 {
				color: #272727;
				text-transform: capitalize;
				font-size: 1.6em;
				font-weight: 800;
			}

			.print-all-btn {
				white-space: nowrap;
				align-self: flex-end;
				margin-top: -4px;
				color: #f8f8fc;
				background-color: #523563;
				padding: 8px 16px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				box-shadow: -1px 1px 4px rgba(22, 49, 30, .2),
					1px -1px 4px #FCFCFF;
				border-radius: 4px;
				transition: all 200ms ease-in-out;
				font-size: 0.9em;

				i {
					margin-top: -2px;
				}

				&:hover {
					box-shadow: none;
					transform: scale(0.95);
				}

				&[disabled] {
					cursor: none;
					pointer-events: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}
		}

		.separator {
			width: 20%;
			height: 4px;
			border-radius: 4px;
			background-color: #523563;
		}

		.header-info-container {
			display: flex;
			align-items: center;
			gap: 32px;
			margin-top: 16px;

			.header-info {
				display: flex;
				align-items: center;
				gap: 8px;

				h3 {
					color: #272727;
					text-transform: capitalize;
					font-size: 1.5em;
					font-weight: 800;
				}

				span {
					color: #9D9D9D;
					text-transform: capitalize;
					font-size: 1em;
					font-weight: 400;
				}
			}

		}

		.bill-date-info {
			align-self: flex-end;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 4px;
			margin-top: 8px;

			.date-separator {
				width: 16px;
				height: 1px;
				background-color: #d8d8d8;
			}

			.date-info-container {
				position: relative;
				background-color: #f8f8fc;
				border: 1px solid #d8d8d8;
				height: 30px;
				min-width: 100px;
				border-radius: 4px;
				padding: 0 12px;
				display: flex;
				align-items: flex-end;
				white-space: nowrap;

				.label-info {
					flex: 1;
					color: #272727;
					font-size: 1em;
					font-weight: 600;
					text-align: center;
					padding-bottom: 2px;
				}

				.label {
					color: #9d9d9d;
					font-size: 1em;
					font-weight: 400;
					position: absolute;
					top: -20%;
					background-color: #f8f8fc;
					padding: 0 4px;
				}
			}

		}
	}

	.bill-list-items {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 8px;

		.list-item-container {
			background-color: #f8f8fc;
			color: #272727;
			padding: 16px;
			border-radius: 4px;
			cursor: pointer;
			display: flex;
			gap: 16px;
			box-shadow: 0px 0px 4px #dddddd;
			transition: all 200ms ease-in-out;
			position: relative;
			overflow: hidden;
			min-height: 88px;

			&::before {
				content: "";
				width: 4px;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: #523563;
				opacity: 0;
			}

			&:hover {
				background-color: #F1F1FC;

				&::before {
					opacity: 1;
				}
			}

			&.selected {
				background-color: #523563;
				color: #f8f8fc;

				.bill-item-details {
					.bill-mainText {
						color: #f8f8fc;
					}
				}

			}

			.item-left {
				flex: 1;
				display: flex;
			}

			.bill-icon {
				align-self: center;
				font-size: 1.6em;
				position: relative;

				.icon-indicator {
					position: absolute;
					bottom: -2px;
					right: -4px;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #46BE46;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 9px;
					color: #f8f8fc;

					&.void {
						background-color: #D35A47;
					}
				}
			}

			.bill-item-details {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 8px;

				.details-row {
					display: flex;
					justify-content: space-between;
					gap: 4px;

					&.flex-end {
						align-items: flex-end;
					}

					.details-col {
						display: flex;
						flex-direction: column;
						gap: 4px;
					}
				}

				.bill-status {
					padding: 4px 16px;
					border-radius: 4px;

					&.credited {
						background-color: #d35a4722;
						color: #d35a47;
						border: 0.25px solid #d35a4744;
					}

					&.bill {
						background-color: #3498DB22;
						color: #3498DB;
						border: 0.25px solid #3498DB44;
					}
				}

				.bill-mainText {
					color: #272727;
					font-size: 1.3em;
					font-weight: 600;

					&.font-xl {
						font-size: 1.4em;
					}
				}

				.bill-subText {
					color: #9D9D9D;
					font-size: 1em;
					font-weight: 400;
					white-space: nowrap;

					&.font-xs {
						font-size: 0.9em;
					}
				}
			}

			.list-item {
				display: flex;
				justify-content: space-between;
				font-size: 1.2em;

				.light-text {
					color: #9D9D9D;
					font-size: 0.9em;
				}

				.dark-text {
					color: #272727;
					font-size: 1.1em;
				}
			}
		}
	}

}