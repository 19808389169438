.sales-book-modal-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	font-size: 1em;

	.modal-body {
		flex: 1;
		overflow: auto;
		padding: 16px 32px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.sales-book-description {
	max-width: 672px;
	margin-bottom: 24px;
	font-size: 1.15em;
	color: #9d9d9d;
	line-height: 1.5;
}

// ui display bug even though same code, so customized
.sales-audit-container {
	position: relative;
	width: 673.6px;
	height: auto;
	min-height: 952px;

	.sales-audit-container-inner {
		background-color: #f8f8fc;
		position: absolute;
		top: 16px;
		left: 50%;
		transform: translate(-50%, -10%) scale(0.8);
		font-size: 12px;
	}
}

// pdf template styles
.pdf-template-wrapper {
	margin: 0 auto;
	box-shadow: 0px 0px 4px #dddddd;
	width: 842px;
	height: 1190px;
	padding: 48px;
	display: flex;
	overflow: hidden;
	position: relative;
	font-size: 12px;

	.pdf-template-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;

		.pdf-template-header-container {
			display: flex;
			flex-direction: column;

			.header-row-wrapper {
				display: flex;
				gap: 16px;
				justify-content: space-between;
				margin: 16px 0;

				.header-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 16px;

					.brand-logo-container {
						display: flex;
						flex-direction: column;
						gap: 8px;

						.brand-image {
							width: 72px;
							height: auto;
							object-fit: contain;
							aspect-ratio: 1/1;
						}

						.brand-text-container {
							display: flex;
							flex-direction: column;
							justify-content: center;
							gap: 8px;

							.brand-text {
								color: #9d9d9d;
								font-size: 1em;
								font-weight: 400;

								&.brand-title {
									color: #523563;
									font-size: 1.5em;
									font-weight: 800;
									text-transform: uppercase;
								}
							}
						}
					}

					.pdf-container {
						display: flex;
						flex-direction: column;
						gap: 8px;
						flex: 1;
						align-items: flex-end;

						.pdf-title {
							text-transform: uppercase;
							color: #523563;
							font-size: 2.2em;
							font-weight: 800;
							letter-spacing: 4px;
							text-align: right;
							margin-bottom: 8px;
						}
					}

					.text-section {
						display: flex;
						align-items: flex-start;
						justify-content: space-between;
						gap: 4px;
						text-align: left;
						font-size: 1.1em;

						.text-col {
							display: flex;
							flex-direction: column;
							align-items: flex-end;
						}

						.dark-text {
							color: #272727;
						}

						.light-text {
							color: #9d9d9d;
							line-height: 1.2;
						}
					}

					.text-container {
						display: flex;
						flex-direction: column;
						gap: 4px;

						.section-title {
							font-size: 1.1em;
							font-weight: 600;
							color: #272727;
							text-transform: uppercase;
							margin-bottom: 4px;
						}

						.dark-text {
							color: #272727;
						}

						.light-text {
							color: #9d9d9d;
							line-height: 1.2;
						}
					}
				}
			}
		}

		.pdf-template-table-container {
			flex: 1;
			padding: 16px 0;
			border-top: 1px dashed #9D9D9D;
			border-bottom: 1px dashed #9D9D9D;

			table.table-container {
				width: 100%;
				border-collapse: collapse;
				text-align: left;
				font-size: 1em;

				td.header-item {
					padding: 10px;
					background-color: #e7e7e7;
					color: #272727;
					font-size: 1.1em;
				}

				tr {
					border-bottom: 1px solid #e7e7e7;

					&.table-footer {
						background-color: #e7e7e7;
					}
				}

				td {
					padding: 10px;
					font-size: 1em;
					color: #272727;


					.table-data-container {

						display: flex;
						flex-direction: column;
						gap: 4px;

						.data-item {
							display: flex;
							flex-direction: column;
							gap: 4px;

							.data-label {
								color: #9d9d9d;
							}
						}
					}
				}
			}
		}

		.pdf-template-footer-container {
			padding-top: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;

			.footer-text {
				font-size: 1em;
				color: #523563;
				font-weight: 600;
			}

			.footer-image {
				width: 16px;
				height: auto;
				object-fit: contain;
				aspect-ratio: 1/1;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.sales-book-modal-container .modal-body {
		.auditLog-container {
			width: 505.2px;
			height: 714px;

			.auditLog-container-inner {
				transform: translate(-50%, -50%) scale(0.6);
			}
		}

		.sales-book-container {
			width: 505.2px;
			height: 714px;

			.sales-book-container-inner {
				transform: translateX(-50%) scale(0.6);
			}
		}
	}

}