.bill_status {
	align-self: flex-end;
	width: 64px;
	height: 24px;
	padding: 0 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1em;
	border-radius: 4px;
	text-transform: capitalize;

	&.draft {
		color: #3498db;
		background-color: #3498db22;
	}

	&.ready {
		color: #82d8b5;
		background-color: #82d8b522;
	}

	&.new {
		color: #ff8774;
		background-color: #ff877422;
	}

	&.editing {
		color: #9d9d9d;
		background-color: #9d9d9d22;
	}

	&.pending,
	&.unsaved {
		color: #e2b60c;
		background-color: #e2b60c22;
	}

	&.success {
		color: #2ecc71;
		background-color: #2ecc7122;
	}

	&.failed {
		color: #b53e2c;
		background-color: #b53e2c22;
	}
}

.drafts-invoices-container {
	flex: 1;
	min-width: 300px;
	height: 100%;
	display: flex;
	flex-direction: column;

	.drafts-invoices-header {
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: space-between;
		padding: 16px;
		margin: 16px;
		background-color: #f8f8fc;
		border-radius: 4px;
		box-shadow: 0px 0px 4px #dddddd;

		.di-header-title {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: 16px;

			.di-action-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				background-color: #523563;
				color: #f8f8fc;
				padding: 8px 16px;
				border-radius: 4px;
				cursor: pointer;
				font-size: 1em;

				&>i {
					margin-bottom: 2px;
				}

				&:hover {
					box-shadow: -3px -3px 4px #fff, 3px 3px 4px rgba(22, 49, 30, 0.2);
				}

				&[disabled] {
					cursor: none;
					pointer-events: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}
		}

		.invoice-header-message {
			font-size: 1em;
			color: #9d9d9d;
			line-height: 1.32;
		}

		.drafts-action {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.drafts-action-btns {
				display: flex;
				align-items: center;
				gap: 8px;

				.action-btn {
					color: #523563;
					background-color: #f8f8fc;
					height: 32px;
					width: 32px;
					font-size: 1.2em;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					box-shadow: 0px 0px 4px #dddddd;
					border-radius: 4px;
					transition: all 200ms ease-in-out;

					&:hover {
						box-shadow: none;
						transform: scale(0.95);
					}

					&.action-del {
						color: #d35a47;
						background-color: #d35a4722;
					}

					&.action-draft {
						background-color: #3498db22;
						color: #3498db;
					}

					&.action-ready {
						background-color: #46be4622;
						color: #46be46;
					}

					&[disabled] {
						box-shadow: none;
						opacity: 0;
						pointer-events: none;
					}
				}
			}
		}
	}

	.draft-select-container {
		display: flex;
		align-items: center;
		border-radius: 4px;
		position: relative;
		cursor: pointer;
		padding-left: 8px;
		margin-left: -8px;

		// Animate the size, outside
		&:hover {
			animation: pulse 1.5s;
			background-color: transparent;
		}

		&.focused {
			background-color: #f1f1fc;
		}

		.select-options {
			color: #9d9d9d;
			font-size: 1em;
			padding: 8px;
		}
	}

	.draft-select {
		width: 12px;
		height: 12px;
		border: 1px solid #d8d8d8;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&.selected {
			border-color: #46be46;
		}

		&.disabled {
			visibility: hidden;
		}
	}

	.drafts-invoices-list {
		flex: 1;
		padding: 4px 0;
		margin-bottom: 16px;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.list-item-container {
			background-color: #f8f8fc;
			border-radius: 4px;
			margin: 0 16px;
			padding: 10px 16px;
			cursor: pointer;
			box-shadow: 0px 0px 4px #dddddd;
			position: relative;
			overflow: hidden;
			min-height: 84px;
			display: flex;

			&[disabled] {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&::before {
				content: "";
				width: 4px;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background-color: #523563;
				opacity: 0;
				// border-radius: 4px;
			}

			&:hover {
				background-color: #f1f1fc;
			}

			&.selected {
				background-color: #f1f1fc;

				&::before {
					opacity: 1;
				}
			}

			.list-item-container-inner {
				flex: 1;
				display: flex;
				align-items: center;
				gap: 16px;

				.list-details {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					.details-col {
						flex: 1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						gap: 4px;

						.list-maintext {
							font-size: 1.1em;
							font-weight: 600;
							color: #272727;
							text-transform: capitalize;
						}

						.list-subtext {
							font-size: 1em;
							color: #9d9d9d;

							&.capitalize {
								text-transform: capitalize;
							}

							&>span {
								white-space: nowrap;
							}
						}
					}

					.details-divider {
						height: 1px;
						min-height: 1px;
						width: 70%;
						background-color: #d8d8d888;
						margin: 4px 0;
					}

					.details-row {
						display: flex;
						align-items: flex-end;
						margin-top: 4px;

						.list-smalltext {
							flex: 1;
							font-size: 0.9em;
							color: #9d9d9d;
						}
					}

					.valid-indicator {
						display: flex;
						align-items: flex-end;
						gap: 8px;
						font-size: 0.9em;
						color: #46be46;

						.valid-icon {
							border: 1.5px solid #46be46;
							border-top-color: transparent;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							font-size: 13px;
							position: relative;
							transform: rotate(45deg);

							i {
								transform: rotate(-45deg);
								font-weight: 600;
								position: absolute;
								top: -50%;
								left: 0;
							}
						}
					}
				}
			}
		}

		.new-draft-btn {
			min-height: 72px;
			border: 1px dashed #d8d8d888;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 4px;
			margin: 0 16px;
			cursor: pointer;

			color: #d8d8d8;
			font-size: 1.2em;
			transition: all 200ms ease-in-out;

			&:hover {
				color: #523563;
				border-color: #523563;
			}
		}
	}
}

@keyframes pulse {
	0% {
		background-color: #f1f1fc;
	}
}

.modal-template-action-container {
	display: flex;
	flex-direction: column;
	height: 100%;

	.modal-body {
		overflow: auto;
		padding: 16px 32px;

		&::-webkit-scrollbar {
			display: none;
		}

		.modal-template-container {
			position: relative;
			width: 673.6px;
			height: 952px;
			margin: 0 auto;

			.modal-template-container-inner {
				background-color: #f8f8fc;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.8);
				font-size: 12px;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.modal-template-action-container .modal-body .modal-template-container {
		width: 505.2px;
		height: 714px;

		.modal-template-container-inner {
			transform: translate(-50%, -50%) scale(0.6);
		}
	}
}

.dropdown-modal-container {
	position: absolute;
	top: 110%;
	right: 0;
	left: 0;
	background-color: #f8f8fc;
	min-width: 160px;
	z-index: 1;
	border-radius: 4px;
	border: 1px solid #d8d8d844;
	box-shadow: 0px 0px 4px #dddddd;

	.dropdown-item {
		padding: 16px;
		border-bottom: 1px solid #d8d8d844;
		cursor: pointer;
		color: #272727;
		font-size: 12px;
		font-weight: 600;
		transition: all 200ms ease-in-out;
		text-align: center;

		&:hover {
			background-color: #f1f1fc;
		}
	}
}