.nd-button-wrapper {
	display: flex;
	width: 100px;
	height: 32px;
	position: relative;

	&.nd-field-wrapper {
		width: 100%;
		height: auto;

		.nd-field {
			width: 100%;
			min-width: 72px;
			font-size: 1em;
			border: 0;
			outline: 0;
			border: 1px solid #d8d8d844;
			background-color: #f8f8fc;
			height: 32px;
			border-radius: 4px;
			padding: 0 8px;
			color: #272727;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.nd-button {
		display: flex;
		width: 100px;
		font-size: 14px;
		color: #9d9d9d;
		border-radius: 5px;
		align-items: center;
		justify-content: center;

	}

	.nd-content-wrapper {
		display: none;
		position: absolute;
		top: 32px;
		padding-top: 4px;
		z-index: 999;
		// border: 1px solid green;

		&.show {
			display: block;
		}

		.nd-component-wrapper {
			width: 230px;
			padding: 10px;
			font-size: 12px;
			border-radius: 5px;
			background-color: #f8f8fc;
			box-shadow: 0px 0px 8px #dddddd;

			.nd-button-container {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.nd-calendar-title {
					font-size: 9px;
					height: 12px;
				}

				.nd-today-buttons {
					display: flex;
					flex: 1;
					gap: 5px;
					// border: 1px solid red;
					justify-content: space-between;
					margin-bottom: 5px;

					>div {
						display: flex;
						justify-content: center;
						min-width: 30px;
						padding: 4px;
						border-radius: 4px;

						&:hover {
							cursor: pointer;
							background-color: #3498db;
							color: #f8f8fc;
						}

					}
				}
			}

			.nd-calender {
				display: grid;
				grid-template-columns: 30px 30px 30px 30px 30px 30px 30px;
				font-size: 9px;
				border-radius: 5px;

				.nd-days {
					display: flex;
					height: 20px;
					font-weight: bold;
					justify-content: center;
					align-items: center;
					// background-color: lightblue;
				}

				.nd-date {
					display: flex;
					height: 30px;
					justify-content: center;
					align-items: center;
					color: #d8d8d8;

					&.clickable {
						color: #272727;
						background-color: transparent;

						&:hover {
							cursor: pointer;
							color: #523563;
						}
					}

					&.today {
						background-color: #3498db;
						color: #f8f8fc;
						box-sizing: border-box;
						font-weight: bold;

						&:hover {
							color: #f8f8fc;
						}
					}

					&.select {
						background-color: #523563;
						color: #f8f8fc;
						font-weight: bold;

						&:hover {
							color: #f8f8fc;
						}
					}
				}
			}
		}
	}



	&:hover {
		.nd-button {
			cursor: pointer;
			color: #f8f8fc;
			background-color: #3498db;
		}

		.nd-content-wrapper {
			display: block;
		}
	}

	&.nd-display-constant {
		width: 285px;
		height: fit-content;


		.nd-content-wrapper {
			position: relative;
			top: 0px;
			padding: 0px 0px 10px 10px;

		}
	}


}