.invoice-form-container {
	padding: 16px 0;
	font-size: 12px;

	.label-title {
		font-size: 1.1em;
		font-weight: 600;
		color: #272727;
		margin-top: 8px;
	}

	input[type="number"] {
		-moz-appearance: textfield;
		appearance: textfield;
	}

	input:disabled {
		opacity: 0.5;
	}

	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.checkbox-wrapper {
		display: flex;
		gap: 8px;
		align-items: center;
		// height: 32px;

		.checkbox-label {
			font-size: 1em;
			color: #272727;
			margin-top: 4px;

			&.disabled {
				color: #9D9D9D;
			}
		}
	}

	.input-row-wrapper {
		display: flex;
		justify-content: space-between;
		gap: 16px;
		flex-wrap: wrap;
		position: relative;
	}

	.input-wrapper {
		flex: 1;
		display: flex;
		flex-direction: column;

		.input-label {
			font-size: 1em;
			color: #9d9d9d;
			font-weight: 400;
			margin-bottom: 4px;
			white-space: nowrap;
		}

		.validation-error {
			color: #d35a47;
			font-size: 0.85em;
		}
	}

	.input-box-wrapper {
		width: 100%;
		font-size: 1em;
		border: 0;
		outline: 0;
		border: 1px solid #d8d8d844;
		background-color: #f8f8fc;
		height: 32px;
		border-radius: 4px;
		padding: 0 8px;
		color: #272727;

		&.fixed-width {
			height: 32px;
			max-width: 132px;
			text-align: right;
			padding: 0px;
		}
	}

	.bill-header-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		padding-bottom: 16px;
		border-bottom: 1px dashed #d8d8d855;

		.bill-details-container {
			display: flex;
			justify-content: space-between;
			gap: 16px;
			flex-wrap: wrap;

			.row-item {
				// min-width: 180px;
				min-width: 240px;
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 16px;

				.customer-container {
					border: 1px solid #d8d8d844;
					background-color: #f8f8fc;
					border-radius: 4px;
					padding: 16px;
					display: flex;
					flex-direction: column;
					gap: 24px;

					.customer-details-container {
						display: flex;
						align-items: center;
						gap: 24px;

						.customer-img-container {
							width: 50px;
							height: 50px;
							border-radius: 50%;
							border: 1px solid #d8d8d8;
							color: #9d9d9d;

							.customer-img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								overflow: hidden;
								display: flex;
								align-items: center;
								justify-content: center;

								img {
									width: 100%;
									aspect-ratio: 1/1;
									object-fit: cover;
								}
							}
						}

						.customer-info-container {
							display: flex;
							flex-direction: column;
							gap: 4px;

							h4.info-primarytext {
								font-size: 1.32em;
								font-weight: 600;
								color: #272727;
							}

							.info-subtext {
								font-size: 0.9em;
								font-weight: 400;
								color: #9d9d9d;
								text-transform: lowercase;
							}
						}
					}

					.customer-action-container {
						display: flex;
						justify-content: flex-end;
						gap: 16px;

						.customer-action-btn {
							display: flex;
							align-items: center;
							height: 28px;
							padding: 0 8px;
							border-radius: 4px;
							background-color: #3498db22;
							color: #3498db;
							font-size: 1em;
							cursor: pointer;
							transition: all 200ms ease-in-out;

							&.deselect {
								background-color: #d35a4722;
								color: #d35a47;
							}

							&:hover {
								background-color: #f8f8fc;
							}
						}
					}
				}

				.add-customer-btn {
					height: 32px;
					display: flex;
					background-image: conic-gradient(from var(--border-angle), transparent 20%, #3498db);
					animation: bg-spin 3s linear infinite;
					border-radius: 4px;

					.btn-text {
						flex: 1;
						color: #3498db;
						font-size: 1em;
						margin: 2px;
						background-color: #f8f8fc;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 4px;
						transition: all 200ms ease-in-out;
					}

					@keyframes bg-spin {
						to {
							--border-angle: 1turn;
						}
					}

					&:hover {
						animation-play-state: paused;
						cursor: pointer;

						.btn-text {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	.bill-items-container {
		padding: 8px 0;
		border-bottom: 1px dashed #d8d8d855;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.item-category-container {
			display: grid;
			grid-template-columns: 32px 1fr 0.3fr 0.5fr 0.5fr 20px;
			gap: 8px;

			&.vat-col {
				grid-template-columns: 32px 56px 1fr 0.3fr 0.5fr 0.5fr 20px 20px;
			}

			.category-text {
				color: #272727;
				font-size: 1em;
				font-weight: 400;
			}
		}

		.item-details-container {
			display: grid;
			grid-template-columns: 32px 1fr 0.3fr 0.5fr 0.5fr 20px;
			gap: 8px;

			&.vat-col {
				grid-template-columns: 32px 56px 1fr 0.3fr 0.5fr 0.5fr 20px 20px;
			}

			.input-action {
				min-width: 20px;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.1em;
				color: #d35a47;
				cursor: pointer;

			}
		}

		.row-btns {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.itemfield-btn {
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 12px 0;
				font-size: 1.1em;
				font-weight: 400;
				color: #3498db;
				cursor: pointer;
			}
		}
	}

	.bill-footer-container {
		padding-top: 16px;
		padding-bottom: 32px;
		display: flex;
		justify-content: space-between;
		gap: 16px;
		flex-wrap: wrap-reverse;

		.footer-description-container {
			flex: 1 0 160px;

			.textarea-wrapper {
				padding: 8px;
				width: 100%;
				max-width: 100%;
				font-size: 1em;
				border: 0;
				outline: 0;
				border: 1px solid #d8d8d844;
				background-color: #f8f8fc;
				border-radius: 4px;
				resize: vertical;
				height: 100%;
			}
		}

		.bill-total-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;

			.bill-total-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 8px;
				height: 36px;
				padding: 0 20px;
				min-width: 320px;

				&.highlighted {
					background-color: #523563;

					.row-item {
						font-size: 1.32em;
						color: #f8f8fc;
					}
				}

				.row-item {
					flex: 1 0 80px;
					font-size: 1em;
					color: #9d9d9d;
					display: flex;
					align-items: center;
					justify-content: space-between;

					&.align_right {
						justify-content: flex-end;
						padding: 0 8px;
						text-align: right;
					}

					&.item-header {
						color: #272727;
						text-align: center;
						font-size: 0.9em;
						font-weight: 600;
					}
				}
			}
		}
	}

	.divider {
		height: 2px;
		min-height: 2px;
		border-radius: 4px;
		width: 100%;
		background-color: #52356377;
	}

	.invoice-actions {
		margin-top: 16px;
	}

	.invoice-actions-warn {
		font-size: 1em;
		color: #d35a47;
		font-weight: 500;

	}

	.invoice-form-btns {
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 16px;

		button[disabled] {
			cursor: none;
			pointer-events: none;
			box-shadow: none;
			opacity: 0.5;
		}

		.form-submit-btn {
			border: 0;
			outline: 0;
			font-size: 1em;
			white-space: nowrap;
			color: #523563;
			background-color: #f8f8fc;
			padding: 8px 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2), 1px -1px 4px #fcfcff;
			border-radius: 4px;
			transition: all 200ms ease-in-out;

			&.inverted {
				color: #f8f8fc;
				background-color: #523563;
			}

			i {
				margin-top: -4px;
			}

			&:hover {
				box-shadow: none;
				transform: scale(0.95);
			}
		}
	}
}

.datalist-container {
	position: absolute;
	top: 110%;
	background-color: #f8f8fc;
	width: 100%;
	z-index: 1;
	border-radius: 4px;
	border: 1px solid #d8d8d844;
	// padding: 0 16px;
	max-height: 220px;
	overflow: auto;
	box-shadow: 0px 0px 4px #dddddd;

	.list-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 16px;
		border-bottom: 1px solid #d8d8d844;
		cursor: pointer;
		transition: all 200ms ease-in-out;

		h5 {
			color: #272727;
			font-size: 12px;
			font-weight: 600;
		}

		.phone-text {
			display: flex;
			align-items: center;
			gap: 8px;
			color: #9d9d9d;

			p {
				font-size: 11px;
				font-weight: 400;
				margin-bottom: -2px;
			}
		}

		&:hover {
			background-color: #f1f1fc;
		}
	}
}

@property --border-angle {
	syntax: "<angle>";
	inherits: true;
	initial-value: 0turn;
}

@media screen and (max-width: 1440px) {
	.invoice-form-container {
		font-size: 11px;
	}
}

@media screen and (max-width: 1024px) {
	.invoice-form-container {
		font-size: 10px;
	}
}