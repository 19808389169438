.modal-profile-container {
	position: absolute;
	z-index: 10;
	top: 125%;
	right: 32px;
	padding: 24px;
	border-radius: 2px;
	background: rgb(28, 18, 34);
	background: linear-gradient(180deg, rgba(28, 18, 34, 1) 0%, rgba(82, 53, 99, 1) 70%, rgba(82, 53, 99, 1) 100%);
	box-shadow: -5px 5px 12px 0px #9d9d9d;
	min-width: 250px;
	max-width: 250px;

	.container-pointer {
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 10px solid #1c1222;
		position: absolute;
		right: 8px;
		top: -10px;
	}

	.modal-profile-container-inner {
		display: flex;
		flex-direction: column;
		gap: 16px;
		justify-content: space-between;

		.change-pswrd-wrapper {
			font-size: 12px;
			padding: 8px 0;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.btns-container {
				padding: 8px 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 8px;

				.change-pswrd-btns {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					cursor: pointer;
					height: 28px;
					padding: 8px 16px;
					font-size: 0.9em;
					font-weight: 600;
					border-radius: 4px;
					color: #523563;
					background-color: #f8f8fc;
					text-transform: capitalize;
					transition: all 350ms ease-in-out;

					&:hover {
						transform: scale(0.95);
					}

					i {
						font-size: 0.9em;
					}

					&[disabled] {
						cursor: none;
						pointer-events: none;
						box-shadow: none;
						opacity: 0.5;
					}
				}
			}
		}

		.profile-view-btn {
			align-self: flex-end;
			display: flex;
			align-items: center;
			gap: 4px;
			padding: 8px;
			padding-top: 0;
			cursor: pointer;
			font-size: 12px;
			color: #f8f8fc;
			text-transform: capitalize;
			transition: all 350ms ease-in-out;

			&:hover {
				transform: scale(1.1);
			}

			i {
				font-size: 0.8em;
				margin-top: 2px;
			}
		}

		.profile-action-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			cursor: pointer;
			height: 32px;
			font-size: 12px;
			font-weight: 600;
			border-radius: 4px;
			color: #523563;
			background-color: #f8f8fc;
			text-transform: capitalize;
			transition: all 350ms ease-in-out;

			&:hover {
				transform: scale(0.95);
			}

			i {
				font-size: 0.9em;
			}

			&[disabled] {
				cursor: none;
				pointer-events: none;
				box-shadow: none;
				opacity: 0.5;
			}
		}

		.profile-img-container {
			align-self: center;
			width: 110px;
			height: 110px;
			border-radius: 50%;
			border: 1px solid #d8d8d8;
			position: relative;
			cursor: pointer;
			transition: all 250ms ease-in-out;

			&:hover {
				box-shadow: 0px 0px 8px #dddddd;
				transform: scale(0.95);
			}

			.profile-img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #f8f8fc;
				color: #523563;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: contain;
				}
			}

			.profile-verified {
				position: absolute;
				bottom: 4px;
				right: 8px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid #f8f8fc;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				font-weight: 900;
				color: #f8f8fc;

				&.active {
					background-color: #46BE46;
				}

				&.inactive {
					background-color: #B53E2C;
				}
			}
		}

		h2.profile-maintext {
			font-size: 16px;
			font-weight: 600;
			color: #f8f8fc;
		}

		p.profile-subtext {
			font-size: 11px;
			font-weight: 400;
			color: #d8d8d8;
		}

		.profile-details {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 8px;

			.profile-status-role {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 16px 0;

				.separator {
					width: 1px;
					height: 20px;
					background-color: #f8f8fc;
				}

				.status-role-container {
					flex: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 4px;

					.sr-value {
						font-size: 14px;
						font-weight: 600;
						color: #f8f8fc;
						text-transform: capitalize;
					}

					.sr-label {
						font-size: 10px;
						font-weight: 400;
						color: #9d9d9d;
					}
				}
			}
		}
	}
}