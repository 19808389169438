.topnav-container {
	background-color: #1c1222;
	border-bottom: 1px solid #9d9d9d;
	padding-bottom: 16px;

	.topnav-container-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
		position: relative;
		z-index: 10;
		min-height: 48px;
		padding: 4px 32px;
	}

	.topnav-left-section {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		.logo-img {
			width: 32px;
			aspect-ratio: 1/1;
			transition-duration: 500ms;
			object-fit: contain;

			&.logo-rotate {
				transform: rotate(360deg);
			}
		}

		.logo-text {
			color: #f8f8fc;
			transform-origin: left;
			font-weight: 800;
			font-size: 20px;
			transition-duration: 200ms;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.topnav-right-section {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		.search-container {
			display: flex;
			align-items: center;
			background-color: #f8f8fc;
			border-radius: 4px;
			padding-right: 8px;

			i {
				font-size: 14px;
				color: #523563;
			}

			.input-box-wrapper {
				font-size: 12px;
				border: 0;
				outline: 0;
				background-color: #F8F8FC;
				height: 36px;
				border-radius: 4px;
				padding: 0 8px;
			}
		}

		.topnav-item {
			font-size: 18px;
			color: #F8F8FC;
			padding: 0 8px;
		}

		.user-container {
			background-color: #f8f8fc;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			color: #523563;
			font-size: 14px;
		}
	}

}

// @media screen and (max-width: 1024px) {
// 	.screen-wrapper {

// 		main.main-wrapper {

// 			.component-wrapper {
// 				// padding: 16px;

// 			}
// 		}
// 	}
// }