.modal-wrapper {
	position: fixed;
	top: 48px;
	right: -100%;
	width: 100%;
	height: calc(100% - 48px);
	z-index: 1; // parent position relative, z-index: 0
	display: flex;

	.modal_overlay {
		cursor: pointer;
		width: 100%;
		height: 100%;
		background-color: #27272733;
		opacity: 0;
		transition: all 400ms ease-in-out;
	}

	.modal-container {
		flex: 1;
		min-width: 60%;
		position: absolute;
		height: 100%;
		top: 0;
		right: -100%;
		transition: right 400ms ease-in-out;
		background-color: #f8f8fc;
		border-top-left-radius: 16px;
		border-bottom-left-radius: 16px;
		overflow: hidden;
		z-index: 1;

		// styles for children component's header part
		.action-warn {
			font-size: 1em;
			color: #d35a47;
			font-weight: 500;

		}

		.action-container {
			display: flex;
			gap: 8px;
			align-items: center;
			justify-content: flex-end;



			.action-btn {
				font-size: 0.9em;
				white-space: nowrap;
				height: 26px;
				color: #f8f8fc;
				background-color: #523563;
				padding: 8px 16px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2), 1px -1px 4px #fcfcff;
				border-radius: 4px;
				transition: all 200ms ease-in-out;
				margin: 12px 0;

				&.inverted {
					color: #523563;
					background-color: #f8f8fc;
				}

				&:hover {
					box-shadow: none;
					transform: scale(0.95);
				}

				&[disabled] {
					cursor: none;
					pointer-events: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}
		}

		.modal-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px;
			height: 64px;
			border-bottom: 1px solid #d8d8d877;

			.modal-action {
				padding: 8px 16px;
				color: #272727;
				font-size: 1.24em;
				cursor: pointer;
				transition: all 200ms ease-in-out;

				i {
					color: #3498db;
					margin-right: 8px;
				}

				&:hover {
					transform: scale(0.9);
				}
			}
		}
	}

	&.expanded {
		right: 0;

		.modal_overlay {
			opacity: 1;
		}

		.modal-container {
			right: 0;
		}
	}
}