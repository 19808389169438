.user-item-container {
	flex: 1;
	max-width: calc((100% /3) - 16px);
	min-width: calc((100% /3) - 16px);
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	border-radius: 4px;
	background-color: #f8f8fc;
	box-shadow: 0px 0px 4px #dddddd;
	font-size: 1em;
	transition: all 200ms ease-in-out;
	position: relative;
	overflow: hidden;

	&:hover {
		box-shadow: 0px 0px 12px #dddddd;

		.item-details-container .item-info .info-icon {
			&.a {
				background-color: #46BE46;
				color: #f8f8fc;
			}

			&.b {
				background-color: #3498db;
				color: #f8f8fc;
			}

			&.c {
				background-color: #523563;
				color: #f8f8fc;
			}
		}
	}

	.item-header-container {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.user-id {
			font-size: 1em;
			color: #9d9d9d;
			font-weight: 600;
			align-self: flex-end;
			cursor: copy;
		}

		.item-img-container {
			align-self: center;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			border: 1px solid #d8d8d8;
			position: relative;
			color: #9d9d9d;

			.item_img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: cover;
				}
			}

			.user-verified {
				position: absolute;
				bottom: 4px;
				right: 8px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid #f8f8fc;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				font-weight: 900;
				color: #f8f8fc;

				&.active {
					background-color: #46BE46;
				}

				&.inactive {
					background-color: #B53E2C;

					i {
						font-size: 10px;
					}
				}
			}
		}
	}

	.item-details-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 8px;

		.user-status-role {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 8px;

			.separator {
				width: 1px;
				height: 20px;
				background-color: #9d9d9d;
			}

			.status-role-container {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 4px;

				.sr-value {
					font-size: 1.4em;
					font-weight: 600;
					color: #272727;
					text-transform: capitalize;

					&.active {
						color: #46BE46;
					}

					&.inactive {
						color: #B53E2C;
					}
				}

				.sr-label {
					font-size: 10px;
					font-weight: 400;
					color: #9d9d9d;
				}
			}
		}

		h4.item-mainText {
			color: #272727;
			font-size: 1.4em;
			font-weight: 600;
			text-align: center;
		}

		.details-divider {
			width: 50%;
			height: 4px;
			min-height: 4px;
			border-radius: 4px;
			background-color: #523563;
			margin-top: 4px;
		}

		.item-info {
			display: flex;
			align-items: center;
			gap: 16px;
			margin: 8px 0;

			&.copy {
				cursor: copy;
			}

			.info-icon {
				width: 32px;
				min-width: 32px;
				height: 32px;
				border-radius: 50%;
				background-color: #f1f1fc;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #9d9d9d;
				transition: all 200ms ease-in-out;
			}

			.info-details {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 4px;

				>.subText {
					font-size: 1em;
					color: #9d9d9d;
					font-weight: 400;
				}

				>.mainText {
					font-size: 1.1em;
					color: #272727;
					font-weight: 400;

					&.capitalize {
						text-transform: capitalize;
					}
				}
			}
		}

		.item-action-btn {
			align-self: flex-end;
			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			font-size: 1.1em;
			color: #3498db;
			transition: all 350ms ease-in-out;
			margin-top: 8px;

			&:hover {
				transform: scale(0.9);
			}

			i {
				font-size: 0.9em;
			}
		}
	}

	.new-flag {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.75em;
		font-weight: 900;
		text-transform: uppercase;
		color: #f8f8fc;
		width: 120px;
		height: 24px;
		letter-spacing: 3px;
		position: absolute;
		top: 24px;
		left: 24px;
		background-color: #523563;
		transform: translate(-50%, -50%) rotateZ(-45deg);
	}
}

@media screen and (min-width:1660px) {
	.user-item-container {
		max-width: calc(25% - 18px);
		min-width: calc(25% - 18px);
	}
}

@media screen and (max-width:1260px) {
	.user-item-container {
		max-width: calc(50% - 12px);
		min-width: calc(50% - 12px);
	}
}

@media screen and (max-width:860px) {
	.user-item-container {
		max-width: 100%;
		min-width: 100%;
	}
}