.pagination {
	flex: 1;
	align-items: flex-end;
	display: flex;
	justify-content: flex-end;
	list-style: none;
	padding: 0;

	li {
		cursor: pointer;
		height: 28px;
		border: 1px solid #d8d8d8;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #3498db;

		&.disabled {
			opacity: 0.6;
			cursor: not-allowed;
			color: #d8d8d8;
		}

		a {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 0.9em;
		}

		&.pageLink {
			width: 28px;
			border-right: none;
		}

		&:nth-last-child(2) {
			border-right: 1px solid #d8d8d8 !important;
		}

		&.active {
			font-weight: bold;
			background-color: #3498db;
			color: #f8f8fc;
		}

		&.navLink {
			margin: 0 8px;
			border-radius: 2px;

			a {
				padding: 0 8px;
			}
		}
	}
}