.filter-container {
	flex: 1;
	margin: 16px;
	font-size: 1em;

	.filter-container-inner {
		display: flex;
		flex-direction: column;

		.filter-section-header {
			color: #272727;
			text-transform: capitalize;
			font-size: 1.6em;
			font-weight: 800;
			white-space: nowrap;
		}

		.action-description {
			color: #9d9d9d;
			font-size: 1em;
		}

		.action-row {
			display: flex;
			gap: 8px;
			align-self: flex-end;
		}

		.action-btn {
			white-space: nowrap;
			align-self: flex-end;
			margin-top: -4px;
			color: #f8f8fc;
			background-color: #523563;
			padding: 8px 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			box-shadow: -1px 1px 4px rgba(22, 49, 30, .2),
				1px -1px 4px #FCFCFF;
			border-radius: 4px;
			transition: all 200ms ease-in-out;
			font-size: 0.9em;

			&.inverted {
				background-color: #f8f8fc;
				color: #523563;
			}

			i {
				margin-top: -4px;
			}

			&:hover {
				box-shadow: none;
				transform: scale(0.95);
			}

			&[disabled] {
				cursor: none;
				pointer-events: none;
				box-shadow: none;
				opacity: 0.5;
			}
		}

		.filter-items-container {
			display: flex;
			flex-direction: column;
			flex: 1;
			gap: 16px;
			padding: 24px;
			background-color: #f8f8fc;
			border-radius: 4px;
			box-shadow: 0px 0px 4px #dddddd;
			margin-bottom: 16px;
		}

		.filter-items-label {
			color: #272727;
			font-size: 1.1em;
			font-weight: 600;
		}

		.filter-row {
			display: flex;
			align-items: center;
			gap: 8px;

			.range-divider {
				width: 8px;
				height: 2px;
				background-color: #9d9d9d;
			}
		}

		.input-slider {
			height: 4px;
			border-radius: 4px;
			background-color: #d8d8d8;
			position: relative;

			.input-progress {
				position: absolute;
				left: 25%;
				right: 25%;
				height: 4px;
				border-radius: 4px;
				background-color: #3498db;
			}
		}

		.range-input {
			position: relative;

			input {
				position: absolute;
				top: -4px;
				height: 4px;
				width: 100%;
				-webkit-appearance: none;
				appearance: none;
				background: transparent;
				cursor: grab;
				pointer-events: none;

				&[type="range"]::-webkit-slider-thumb {
					height: 14px;
					width: 14px;
					border-radius: 50%;
					pointer-events: auto;
					-webkit-appearance: none;
					appearance: none;
					background-color: #3498db;
				}

				&[type="range"]::-moz-range-thumb {
					height: 14px;
					width: 14px;
					border: none;
					border-radius: 50%;
					pointer-events: auto;
					-moz-appearance: none;
					appearance: none;
					background-color: #3498db;
				}
			}
		}

		.filter-btn-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 16px;
			padding: 8px 0;

			.filter-btn {
				font-size: 0.9em;
				white-space: nowrap;
				color: #f8f8fc;
				background-color: #523563;
				padding: 8px 16px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2),
					1px -1px 4px #fcfcff;
				border-radius: 4px;
				transition: all 200ms ease-in-out;

				&.inverted {
					color: #523563;
					background-color: #f8f8fc;
				}

				i {
					margin-top: -4px;
				}

				&:hover {
					box-shadow: none;
					transform: scale(0.95);
				}
			}
		}
	}
}