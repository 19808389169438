.login-wrapper {
	width: 100%;
	height: 100vh;
	background-color: #f8f8fc;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;

	.login-container {
		min-width: 300px;
		min-height: 600px;
		width: 900px;
		height: 50%;
		margin: 40px;
		border-radius: 16px;
		overflow: hidden;
		box-shadow: -3px -3px 4px #fff, 3px 3px 4px rgba(22, 49, 30, 0.2);

		.login-container-inner {
			display: flex;
			height: 100%;
			transition: all 300ms ease-in-out;
			position: relative;

			.title-text {
				font-size: 2.4em;
				font-weight: 800;
				color: #523563;
				margin-bottom: 16px;
				text-align: center;

				&.inverted {
					color: #f8f8fc;
				}
			}

			.description-text {
				font-size: 1.1em;
				font-weight: 400;
				color: #9d9d9d;
				text-align: center;
				max-width: 285px;

				&.inverted {
					color: #d8d8d8;
				}
			}

			.form-submit-btn {
				min-width: 160px;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				padding: 12px 24px;
				margin: 16px 0;
				background: #523563;
				color: #f8f8fc;
				border-radius: 24px;
				font-size: 1.1em;
				text-transform: uppercase;
				cursor: pointer;
				transition: all 250ms ease-in-out;

				&:hover {
					transform: scale(0.9);
					background: linear-gradient(120deg,
							#1c1222 0%,
							#523563 100%);
				}

				&.inverted {
					border: 1px solid #f8f8fc;
					background-color: transparent;
				}

				&[disabled] {
					cursor: none;
					pointer-events: none;
					box-shadow: none;
					opacity: 0.5;
				}
			}

			.form-container {
				flex: 1;
				background-color: #f8f8fc;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 16px;
				padding: 24px;

				.social-icon-btns {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;

					.icon-btn {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						border: 1px solid #d8d8d8;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				.form-inputs-container {
					align-self: normal;
					margin: 16px;
					display: flex;
					flex-direction: column;
					gap: 8px;

					.input-wrapper {
						display: flex;
						flex-direction: column;

						.input-label {
							font-size: 1em;
							color: #9d9d9d;
						}

						.input-wrapper-inner {
							display: flex;
							align-items: center;
							padding-left: 4px;
							font-size: 1em;
							width: 100%;
							border: 1px solid #d8d8d855;
							border-radius: 4px;
							color: #9d9d9d;

							i {
								width: 28px;
								display: flex;
								align-items: center;
								justify-content: center;
							}

							.input-container {
								background-color: transparent;
								flex: 1;
								border: 0;
								outline: 0;
								height: 40px;
								padding: 0 8px;

								&::placeholder {
									color: #9d9d9d;
								}
							}

							.input-action {
								font-size: 1em;
								color: #d8d8d8;
								cursor: pointer;
								display: flex;
								align-items: center;
								height: 100%;
							}
						}

						.form-validation-error {
							color: #d35a47;
							font-size: 0.9em;
							height: 7px;
							margin-top: 5px;
						}
					}
				}

				.form-change-btn {
					color: #272727;
					font-size: 1.1em;
					font-weight: 400;
					padding: 4px;
					cursor: pointer;
					transition: all 200ms ease-in-out;
					border-bottom: 1px solid transparent;

					&:hover {
						color: #3498db;
						border-bottom: 1px solid #d8d8d855;
					}
				}
			}

			.description-container {
				flex: 1;
				background-color: #1c1222;
				backdrop-filter: opacity(20%);
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 16px;
				padding: 0 24px;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.login-wrapper {
		font-size: 10px;

		.login-container {
			width: 450px;

			.login-container-inner {
				flex-direction: column;

				.description-container {
					flex: 0.4;
				}
			}
		}
	}
}