* {
	font-family: 'SofiaProMedium';
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

body {
	margin: 0;
	font-family: "SofiaProMedium", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* max-height: 1080px; */
}

code {
	font-family: Menlo, Monaco, Consolas, "Courier New", monospace, "SofiaProMedium";
}


@font-face {
	font-family: "SofiaProMedium";
	src: local("SofiaProMedium"), url('./assets/fonts/SofiaPro/sofiapro-medium.ttf') format("truetype");
}
