.company-profile-setting-wrapper {
	display: flex;
	flex-direction: column;

	.company-cover-container {
		height: 72px;
		background: #1c1222;
		background: linear-gradient(180deg,
				#1c1222 0%,
				#523563 70%,
				#523563 100%);
		border-radius: 8px;
		margin: 8px 24px;
		margin-bottom: 0;
	}

	.company-profile-setting-container {
		flex: 1;
		padding: 48px;
		padding-top: 16px !important;
		display: flex;
		gap: 24px;
		margin-top: -48px;
		font-size: 12px;

		.container-title {
			font-size: 1.6em;
			color: #272727;
			font-weight: 800;
		}

		.container-description {
			font-size: 1.1em;
			color: #9d9d9d;
			font-weight: 400;
		}

		.export-btn {
			align-self: flex-end;
			color: #f8f8fc;
			background-color: #523563;
			padding: 8px 16px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			box-shadow: -1px 1px 4px rgba(22, 49, 30, .2),
				1px -1px 4px #FCFCFF;
			border-radius: 4px;
			transition: all 200ms ease-in-out;
			font-size: 1em;

			&.inverted {
				background-color: #f8f8fc;
				color: #523563;
			}

			i {
				margin-top: -4px;
			}

			&:hover {
				box-shadow: none;
				transform: scale(0.95);
			}

			&[disabled] {
				cursor: none;
				pointer-events: none;
				box-shadow: none;
				opacity: 0.5;
			}
		}

		.profile-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 24px;

			.profile-container-inner {
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 24px;
				border-radius: 4px;
				background-color: #f8f8fc;
				box-shadow: 0px 0px 4px #dddddd;

				.profile-section {
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding: 16px 0;

					.input-items-label {
						color: #272727;
						font-size: 1.1em;
						font-weight: 600;
					}

					.minWidth {
						min-width: 132px;
					}
				}

				.profile-divider {
					width: 50%;
					height: 4px;
					min-height: 4px;
					background-color: #523563;
				}

				.img-container {
					width: 132px;
					height: 132px;
					border-radius: 50%;
					border: 1px solid #d8d8d844;
					align-self: center;
					position: relative;

					&.editable {
						cursor: pointer;
						transition: all 250ms ease-in-out;

						&:hover {
							box-shadow: 0px 0px 8px #dddddd;
							transform: scale(1.05);
						}
					}

					.company-image {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						overflow: hidden;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 100%;
							aspect-ratio: 1/1;
							object-fit: contain;
						}
					}

					.img-action {
						position: absolute;
						color: #f8f8fc;
						border: 1px solid #f8f8fc;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						right: 9px;
						bottom: 9px;
						z-index: 1;
						font-size: 10px;
						background-color: #3498db;

						&.active {
							background-color: #46be46;
							font-size: 14px;
						}
					}
				}

				.name-input-field {
					width: 100%;
					outline: 0;
					background-color: #f1f1fc;
					border: 1px solid #d8d8d8;
					border-radius: 4px;


					&:disabled {
						border: 1px solid transparent;
						background-color: transparent;
					}
				}

				.section-header {
					font-size: 1.6em;
					font-weight: 600;
					color: #272727;
					padding: 8px;
					text-align: center;
				}

				.social-section {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					margin-bottom: 8px;

					.company-social-container {
						width: 32px;
						height: 32px;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #f8f8fc;
					}
				}

				.profile-details {
					width: 100%;
					display: flex;
					align-items: center;
					gap: 8px;

					.section-label-text {
						flex: 0.8;
						font-size: 1em;
						font-weight: 400;
						color: #9d9d9d;
						text-align: left;
						padding: 8px 0;
					}

					.profile-input-field {
						flex: 1;
						min-width: 72px;
						font-size: 1em;
						border: 0;
						outline: 0;
						background-color: transparent;
						height: 32px;
						border-radius: 4px;
						padding: 0 8px;
						color: #272727;
						display: flex;
						align-items: center;
						text-align: right;

						&.editableField {
							color: #272727;
							border: 1px solid #d8d8d8;
							background-color: #f1f1fc;
						}
					}

					.input-view {
						padding: 8px 0;
						padding-right: 8px;
						color: #d8d8d8;
						font-size: 12px;
						cursor: pointer;
						transition: all 200ms ease-in-out;

						&:hover {
							color: #523563;
						}
					}
				}

				.action-btns {
					align-self: flex-end;
					display: flex;
					gap: 16px;
				}

				.profile-btn-container {
					display: flex;
					align-items: center;
					font-size: 1.1em;
					align-self: flex-end;
					padding: 8px 16px;
					margin: 8px 0;
					border-radius: 4px;
					color: #f8f8fc;
					background-color: #523563;
					cursor: pointer;
					box-shadow: 0px 0px 4px #dddddd;
					transition: transform 200ms ease-in-out;

					&.inverted {
						background-color: #f8f8fc;
						color: #523563;
					}

					&:hover {
						transform: scale(0.9);
					}

					&[disabled] {
						cursor: none;
						pointer-events: none;
						box-shadow: none;
						opacity: 0.5;
					}
				}
			}
		}

		.company-setting-container {
			// flex: 2.2;
			padding: 24px;
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			background-color: #f8f8fc;
			box-shadow: 0px 0px 4px #dddddd;

			.company-setting-header {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}

			.settings-wrapper {
				flex: 1;

				.setting-container {
					border-top: 1px solid #d8d8d855;
					display: flex;
					gap: 40px;
					flex-wrap: wrap;
					padding: 16px 0;

					.setting-content {
						padding: 16px 0;
						flex: 1;
						min-width: 260px;
						display: flex;
						flex-direction: column;

						.setting-title {
							font-size: 1.1em;
							color: #272727;
							font-weight: 600;
							margin-bottom: 8px;
						}

						.setting-description {
							font-size: 1em;
							font-weight: 400;
							color: #9d9d9d;
							line-height: 1.32;

							span {
								font-weight: 600;
								color: #b53e2c;
							}
						}

						.more-setting-button {
							color: #3498db;
							font-size: 1.1em;
							font-weight: 400;
							margin-top: 16px;
							cursor: pointer;
							align-self: flex-start;
							transition: all 250ms ease-in-out;

							&:hover {
								text-decoration: underline;
							}
						}

						.setting-action {
							margin-top: 16px;
							display: flex;
							flex-direction: column;
							gap: 16px;

							.action-row {
								display: flex;
								justify-content: space-between;
								align-items: center;
								gap: 16px;

								.action-detail {
									display: flex;
									flex-direction: column;
									gap: 4px;

									h5 {
										font-size: 1em;
										color: #272727;
										font-weight: 600;
									}

									p {
										font-size: 1em;
										color: #9d9d9d;
										font-weight: 400;
									}
								}
							}
						}
					}

					.setting-details {
						flex: 2;
						min-width: 260px;
						display: flex;
						align-items: center;

						// overflow-x: hidden;
						.bill-footer {
							width: 100%;
							display: flex;
							justify-content: flex-end;
							border: 1px solid #d8d8d855;
							background-color: #fcfcff;

							.bill-footer-container {
								min-width: 50%;
								display: grid;
								grid-template-columns: 1fr 1fr;
								padding: 16px;

								.bill-item-text {
									padding: 4px 0;
									font-size: 1em;
									color: #272727;
									display: flex;
									align-items: center;
									min-height: 32px;
									white-space: noWrap;

									&.highlighted {
										background-color: #523563;
										color: #f8f8fc;
										padding: 8px;
										font-weight: 800;
										margin-top: 8px;
									}

									&.align-right {
										justify-content: flex-end;
										text-align: right;
									}
								}
							}
						}

						.tnc-container {
							min-width: 100%;
							height: 120px;
							position: relative;
							padding: 4px;
							background-color: #fcfcff;
							border: 1px solid #d8d8d877;
							align-self: flex-start;
							margin: 12px 0;
							overflow: hidden;

							.tnc-container-inner {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%) scale(0.43);
								background-color: #fcfcff;
								transition: all 300ms ease-in-out;
								cursor: pointer;
								z-index: 0;

								.dummyTnc {
									min-width: 590px;
									width: 590px;
									min-height: 192px;
									display: flex;
									flex-direction: column;
									gap: 4px;
									font-size: 12px;
									line-height: 1.2;
									color: #9D9D9D;
									word-break: break-word;

									b {
										font-size: 1.6em;
										font-weight: 800;
										color: #523563;
									}

									.tnc-data {
										font-size: 1.5em;
										padding-left: 12px;
										height: 156px;
										border-bottom: 4px solid #b53e2c;
									}
								}
							}
						}

						.templates-preview-wrapper {
							padding: 16px 0;
							display: flex;
							gap: 24px;
							// overflow-x: auto;

							&::-webkit-scrollbar {
								display: none;
							}

							.template-preview-container {
								min-width: 118px;
								width: 118px;
								height: 167px;
								position: relative;
								padding: 4px;

								.template-selection {
									width: 16px;
									height: 16px;
									border-radius: 50%;
									background-color: #3498db;
									color: #f8f8fc;
									align-items: center;
									justify-content: center;
									font-size: 9px;
									position: absolute;
									right: -6px;
									top: -6px;
									display: none;
								}

								.template-preview-container-inner {
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%) scale(0.14);
									background-color: #fcfcff;
									border: 4px solid #d8d8d877;
									transition: all 300ms ease-in-out;
									cursor: pointer;
									z-index: 0;
								}

								&.selected {
									.template-selection {
										display: flex;
									}

									.template-preview-container-inner {
										// position: absolute;
										// top: 50%;
										// left: 50%;
										// transform: translate(-50%, -50%) scale(0.2);
										border: 4px solid #3498db;
										box-shadow: 0px 0px 4px #dddddd;
									}
								}

								&:hover {
									.template-selection {
										display: none;
									}

									.template-preview-container-inner {
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%) scale(0.24);
										box-shadow: 0px 0px 20px 8px #dddddd;
										z-index: 1;
									}
								}
							}
						}
					}
				}
			}

			.settings-btn-wrapper {
				padding: 16px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 16px;
				border-top: 1px solid #d8d8d855;

				.setting-btn {
					color: #f8f8fc;
					background-color: #523563;
					padding: 8px 16px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					box-shadow: -1px 1px 4px rgba(22, 49, 30, 0.2),
						1px -1px 4px #fcfcff;
					border-radius: 4px;
					transition: all 200ms ease-in-out;

					&[disabled] {
						opacity: 0.7;
						background-color: #d8d8d8;
						cursor: not-allowed;

						&:hover {
							transform: none;
						}
					}

					&.inverted {
						background-color: #f8f8fc;
						color: #523563;
					}

					i {
						margin-top: -4px;
					}

					&:hover {
						box-shadow: none;
						transform: scale(0.95);
					}
				}
			}
		}
	}
}

.ck-editor__editable_inline {
	padding-left: 24px !important;
}

@media screen and (max-width:1580px) {
	.company-profile-setting-wrapper {
		.company-cover-container {
			margin: 8px 16px;
		}

		.company-profile-setting-container {
			font-size: 10px;
			padding: 32px;
		}
	}
}

// @media screen and (max-width: 1440px) {
// 	.company-profile-wrapper .company-profile-container .company-setting-container .company-setting-container-inner .settings-wrapper .settings-container {
// 		min-width: 50%;
// 		max-width: 50%;
// 	}
// }

@media screen and (max-width: 1190px) {
	.company-profile-setting-wrapper {
		.company-cover-container {
			margin: 8px;
		}

		.company-profile-setting-container {
			padding: 24px;
			flex-direction: column;

			.profile-container {
				// flex-direction: row;

				.profile-container-inner .section-wrapper {
					flex-direction: row;
					gap: 16px;
				}
			}
		}
	}
}