.invoice-bill-wrapper {
	height: 100%;
	padding: 48px;
	font-size: 12px;
	overflow-x: hidden;

	.invoice-bill-container {
		display: flex;
		height: 100%;
		min-height: 1140px;

		.bill-template-action-container {
			flex-basis: 673.6px;
			margin: 16px;
			position: relative;

			.temp-section-header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: wrap;
				gap: 8px;
				padding: 24px;
				background-color: #f8f8fc;
				border-radius: 4px;
				box-shadow: 0px 0px 4px #dddddd;
				margin-bottom: 16px;

				h2 {
					color: #272727;
					text-transform: capitalize;
					font-size: 1.6em;
					font-weight: 800;
				}

				.bill-action-container {
					display: flex;
					gap: 16px;
					align-items: center;
					justify-content: flex-end;



					.action-btn {
						// flex: 1;
						color: #f8f8fc;
						background-color: #523563;
						padding: 8px 16px;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 8px;
						box-shadow: -1px 1px 4px rgba(22, 49, 30, .2),
							1px -1px 4px #FCFCFF;
						border-radius: 4px;
						transition: all 200ms ease-in-out;

						&.inverted {
							background-color: #f8f8fc;
							color: #523563;
						}

						i {
							margin-top: -4px;
						}

						&:hover {
							box-shadow: none;
							transform: scale(0.95);
						}

					}
				}
			}

			.bill-template-wrapper {
				display: flex;
				gap: 16px;
				// width: 673.6px;
				height: 952px;
				box-shadow: 0px 0px 4px #dddddd;
				overflow: hidden;

				.bill-template-container {
					position: relative;
					min-width: 100%;
					// height: 952px;
					display: none;

					&.active {
						display: block;
					}

					.bill-template-container-inner {
						background-color: #f8f8fc;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%) scale(0.8);
						font-size: 12px;
					}
				}

			}

			.bill-page-navigation {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 104%;
				display: flex;
				justify-content: space-between;

				button.navigation-btn {
					background-color: #523564;
					color: #fff;
					border: none;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					cursor: pointer;
					font-size: 1.4em;
					transition: all 200ms ease-in-out;

					&:disabled {
						opacity: 0;
					}
				}
			}
		}
	}


	.modal-template-action-container {
		display: flex;
		flex-direction: column;
		height: 100%;

		.modal-body {
			flex: 1;
			overflow: auto;
			padding: 16px 32px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.credit-note-form {
				max-width: 673.6px;
				width: 100%;
				margin: 0 auto;
				padding-bottom: 16px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				h2.section-header {
					color: #272727;
					text-transform: capitalize;
					font-size: 1.6em;
					font-weight: 800;
				}

				.input-form-container {
					display: flex;
					flex-direction: column;
					gap: 16px;
				}
			}

			&::-webkit-scrollbar {
				display: none;
			}

			.modal-template-wrapper {
				display: flex;
				flex-direction: column;
			}

			.modal-template-container {
				position: relative;
				width: 673.6px;
				height: 952px;
				margin: 0 auto;

				.modal-template-container-inner {
					background-color: #f8f8fc;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) scale(0.8);
					font-size: 12px;
				}
			}
		}

	}
}

@media screen and (max-width: 1580px) {
	.invoice-bill-wrapper {
		font-size: 11px;
		padding: 32px;

		.invoice-bill-container {
			min-height: 1100px;

			.bill-template-action-container {
				flex-basis: 589.4px;

				.bill-template-wrapper {
					height: 833px;

					.bill-template-container {
						// width: 589.4px;
						// height: 833px;

						.bill-template-container-inner {
							transform: translate(-50%, -50%) scale(0.7);
						}
					}
				}
			}
		}
	}
}



@media screen and (max-width: 1400px) {
	.invoice-bill-wrapper {
		.invoice-bill-container {

			.bill-template-action-container {
				flex-basis: 505.2px;

				.bill-template-wrapper {
					height: 714px;

					.bill-template-container {
						// width: 505.2px;
						// height: 714px;

						.bill-template-container-inner {
							transform: translate(-50%, -50%) scale(0.6);
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1290px) {
	.invoice-bill-wrapper {
		padding: 24px;
		font-size: 10px;

		.invoice-bill-container {
			// min-height: 948px;

			.bill-template-action-container {
				flex-basis: 421px;

				.bill-template-wrapper {
					height: 595px;

					.bill-template-container {
						// width: 421px;
						// height: 595px;

						.bill-template-container-inner {
							transform: translate(-50%, -50%) scale(0.5);
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1190px) {
	.invoice-bill-wrapper {

		.invoice-bill-container {

			.bill-template-action-container {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.invoice-bill-wrapper {
		padding: 0px;
		font-size: 9px;

		.invoice-bill-container {
			padding: 20px;
			overflow-x: auto;
			min-height: 1100px;
		}

		.modal-template-action-container .modal-body .modal-template-container {
			width: 505.2px;
			height: 714px;

			.modal-template-container-inner {
				transform: translate(-50%, -50%) scale(0.6);
			}
		}
	}

}