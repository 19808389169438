.employee-list-wrapper {
	padding: 48px;
	font-size: 12px;

	.wrapper-row {
		display: flex;
		height: 100%;

		.employee-list-container {
			flex: 3;
			margin: 16px;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.section-header-container {
				padding: 24px;
				background-color: #f8f8fc;
				border-radius: 4px;
				box-shadow: 0px 0px 4px #dddddd;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 16px;

				.section-header-title {
					color: #272727;
					text-transform: capitalize;
					font-size: 1.6em;
					font-weight: 800;
				}

				.header-action-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;
					background-color: #523563;
					color: #f8f8fc;
					padding: 8px 16px;
					border-radius: 4px;
					cursor: pointer;
					font-size: 1em;

					&>i {
						margin-bottom: 2px;
					}

					&:hover {
						box-shadow: -3px -3px 4px #fff, 3px 3px 4px rgba(22, 49, 30, 0.2);
					}
				}
			}

			.employee-list-items {
				display: flex;
				gap: 24px;
				flex-wrap: wrap;
			}
		}
	}
}

@media screen and (max-width:1580px) {
	.employee-list-wrapper {
		font-size: 11px;
		padding: 32px;
	}
}

@media screen and (max-width: 1290px) {
	.employee-list-wrapper {
		font-size: 10px;
		padding: 24px;
	}
}

@media screen and (max-width: 800px) {
	.employee-list-wrapper {
		font-size: 9px;
		padding: 20px;
	}
}