.template-wrapper {
	margin: 0 auto;
	box-shadow: 0px 0px 4px #dddddd;

	--size-xs: 10px;
	--size-sm: 12px;
	--size-md: 16px;
	--size-lg: 24px;
	--size-xl: 40px;

	width: 842px;
	height: 1190px;
	padding: var(--size-xl);
	display: flex;
	overflow: hidden;
	position: relative;

	.template-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;

		.template-header-container {
			display: flex;
			flex-direction: column;
			gap: var(--size-lg);
			margin-bottom: var(--size-md);

			.dark-text {
				font-size: 1em;
				font-weight: 600;
				color: #272727;
				white-space: nowrap;
			}

			.light-text {
				font-size: 1em;
				font-weight: 400;
				color: #434343;
				font-weight: 500;
				line-height: 1.2;

				&.uppercase {
					text-transform: uppercase;
				}

				&.capitalize {
					text-transform: capitalize;
				}

				&.lowercase {
					text-transform: lowercase;
				}
			}

			.header-row-wrapper {
				display: flex;
				gap: var(--size-md);
				justify-content: space-between;

				.header-row-item {
					flex: 1;
					display: flex;

					.brand-logo-container {
						display: flex;
						flex-direction: column;
						gap: 8px;

						.brand-image {
							width: 72px;
							height: auto;
							object-fit: contain;
							aspect-ratio: 1/1;
						}

						.brand-text-container {
							display: flex;
							flex-direction: column;
							justify-content: center;
							gap: 8px;

							.brand-text {
								color: #434343;
								font-weight: 500;
								font-size: 1em;
								font-weight: 400;

								&.brand-title {
									color: #523563;
									font-size: 1.5em;
									font-weight: 800;
									text-transform: uppercase;
								}
							}
						}
					}

					.invoice-container {
						display: flex;
						flex-direction: column;
						gap: var(--size-xs);
						flex: 1;
						align-items: flex-end;


						.invoice-title {
							text-transform: uppercase;
							color: #523563;
							font-size: 2em;
							font-weight: 800;
							letter-spacing: 4px;
							text-align: left;

							&.credit {
								color: #D35A47;
							}

							&.draft {
								color: #3498db;
							}

							&.bill {
								color: #523563;
							}
						}

						.invoice-text-container {
							display: flex;
							flex-direction: column;
							gap: 4px;

							.text-section {
								display: flex;
								align-items: center;
								justify-content: space-between;
								gap: 4px;
								text-align: left;

								.text-col {
									display: flex;
									flex-direction: column;
									align-items: flex-end;
								}
							}
						}

					}
				}

				.bill-container {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: var(--size-md);

					.bill-flag-container {
						flex: 1;

						.bill-flag {
							width: 100%;
							height: 28px;
							color: #f8f8fc;
							background-color: #523563;
							padding: 0 8px;
							font-size: 1.24em;
							font-weight: 800;
							text-transform: uppercase;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: flex-end;

							&::before {
								content: "";
								position: absolute;
								left: 0;
								top: 0;
								width: 0;
								height: 0;
								border-top: 14px solid transparent;
								border-left: 28px solid #f8f8fc;
								border-bottom: 14px solid transparent;
							}
						}
					}

					.text-container {
						display: flex;
						flex-direction: column;
						gap: 4px;

						.section-title {
							font-size: 1em;
							font-weight: 600;
							color: #272727;
							text-transform: uppercase;
							margin-bottom: 4px;
						}
					}

					.text-section {
						display: flex;
						align-items: center;
						gap: 4px;
					}
				}
			}

			.header-col-wrapper {
				display: flex;
				flex-direction: column;
				gap: var(--size-md);
			}

			.header-text-section {
				display: flex;
				align-items: center;
				gap: 8px;
			}
		}

		.template-body-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			padding: var(--size-md) 0;
			border-top: 1px dashed #9D9D9D;

			.amount-item-indicator {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 32px;
				font-size: 1.1em;
				color: #434343;
				font-weight: 500;
				margin-top: 16px;
			}

			.table-grid-container {
				display: grid;
				grid-template-columns: 1fr 56px 100px 100px;
				padding: var(--size-xs) 0;

				&.five-col {
					grid-template-columns: 80px 1fr 56px 100px 100px;
				}

				&.highlighted {
					border-top: 1px solid #9d9d9d55;
					border-bottom: 1px solid #9d9d9d55;
					background-color: #D8D8D8;
				}

				.table-item-text {
					font-size: 1em;
					color: #272727;
					padding: 0 var(--size-xs);

					&.capitalize {
						text-transform: capitalize;
					}

					&.align-right {
						justify-content: flex-end;
						text-align: right;
					}
				}
			}

			.table-footer-container {
				display: grid;
				grid-template-columns: 1fr 0.5fr 0.5fr;
				border-top: 1px solid #D8D8D855;
				padding: var(--size-md) 0;

				.span-grid-item {
					grid-row: 1/span 7;
					padding: var(--size-xs) 0;
					margin-right: var(--size-xs);
					display: flex;
					flex-direction: column;
					gap: 8px;

					.span-text {
						line-height: 1.2;
						font-size: 1em;
						color: #434343;
						font-weight: 500;

						&.highlighted {
							color: #523563;
							font-size: 1em;
							margin-bottom: 4px;
						}

						&.ellipsis-text {
							display: -webkit-box;
							-webkit-box-orient: vertical;
							line-clamp: 6;
							-webkit-line-clamp: 6;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						&.capitalize {
							text-transform: capitalize;
						}
					}

					.payment-method-indicator {
						display: flex;
						flex-direction: column;
					}
				}

				.table-item-text {
					font-size: 1em;
					color: #272727;
					padding: 0 var(--size-xs);
					padding-top: var(--size-xs);
					display: flex;
					align-items: center;

					&.highlighted {
						background-color: #523563;
						color: #f8f8fc;
						padding: var(--size-xs);
						font-weight: 800;
						margin-top: var(--size-xs);
					}

					&.align-right {
						justify-content: flex-end;
						text-align: right;
					}
				}
			}
		}

		.template-footer-container {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.template-footer-row {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				gap: 24px;

				.footer-item {
					display: flex;
					flex-direction: column;
					gap: 16px;

					&.flex-end {
						align-items: flex-end;
					}

					.footer-text {
						color: #272727;
						font-size: 1em;
						font-weight: 800;

					}

					.print-details {
						font-size: 1em;
						line-height: 1.2;
						color: #434343;
						font-weight: 500;
					}

					.sign-space {
						width: 132px;
						height: 72px;
						border-bottom: 1px dashed #9D9D9D;
					}

					.footer-tnc {
						display: flex;
						flex-direction: column;
						gap: 4px;
						font-size: 0.9em;
						line-height: 1.2;
						color: #434343;
						font-weight: 500;
						word-break: break-word;

						b {
							font-size: 1em;
							font-weight: 800;
							color: #523563;
						}

						.tnc-data {
							padding-left: 24px;
							max-height: 104px;
							overflow: hidden;
						}
					}
				}
			}

			.footer-divider {
				height: 2px;
				min-height: 2px;
				width: 60%;
				background-color: #523563;
			}

			.brand-details-container {
				display: flex;
				flex-direction: column;
				gap: 4px;

				.details-row {
					display: flex;
					gap: 16px;

					.details-text {
						color: #434343;
						font-weight: 500;
						font-size: 0.9em;

						b {
							color: #272727;
						}

						&.capitalize {
							text-transform: capitalize;
						}
					}
				}

			}
		}
	}

	.template-status {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		font-weight: 900;
		text-transform: uppercase;
		letter-spacing: 4px;
		color: #f8f8fc;
		position: absolute;
		top: 5%;
		left: 7%;
		width: 250px;
		background-color: #523563;
		height: 50px;
		transform: translate(-50%, -50%) rotateZ(-45deg);

		&.void {
			background-color: #D35A47;
		}
	}

}