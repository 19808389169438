.activity-monitor-wrapper {
	height: 100%;
	padding: 48px;
	font-size: 12px;
	overflow-x: hidden;

	.activity-monitor-container {
		display: flex;
		height: 100%;
		overflow: hidden;

		.activities-list-wrapper {
			flex: 3;
			margin: 16px;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.section-header-container {
				padding: 24px;
				background-color: #f8f8fc;
				border-radius: 4px;
				box-shadow: 0px 0px 4px #dddddd;

				.section-header-title {
					color: #272727;
					text-transform: capitalize;
					font-size: 1.6em;
					font-weight: 800;
				}
			}

			.activities-container {
				overflow-y: auto;
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 16px;
				padding: 24px;
				background-color: #f8f8fc;
				border-radius: 4px;
				box-shadow: 0px 0px 4px #dddddd;
				font-size: 1.1em;
			}
		}
	}
}

@media screen and (max-width:1580px) {
	.activity-monitor-wrapper {
		font-size: 11px;
		padding: 32px;
	}
}

@media screen and (max-width: 1290px) {
	.activity-monitor-wrapper {
		font-size: 10px;
		padding: 24px;
	}
}

@media screen and (max-width: 800px) {
	.activity-monitor-wrapper {
		font-size: 9px;
		padding: 20px;
	}
}

@media screen and (max-width: 768px) {
	.activity-monitor-wrapper {
		overflow: auto;

		.activity-monitor-container {
			flex-direction: column;
			overflow: visible;
			height: auto;
		}
	}
}