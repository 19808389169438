.bill-duplicate-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-size: 1em;

	.modal-body {
		overflow: auto;
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.section-header {
			font-size: 1.2em;
			font-weight: 600;
			color: #272727;
		}

		.section-description {
			font-size: 1em;
			font-weight: 400;
			color: #9d9d9d;
		}

		.bill-filters-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.bill-filter-container {
				display: flex;
				align-items: center;
				gap: 8px;
				flex-wrap: wrap;

				.bill-filter-container-inner {
					padding: 4px 8px;
					border: 1px solid #d8d8d8;
					background-color: #f8f8fc;
					color: #272727;
					border-radius: 4px;
				}
			}
		}

		.divider {
			width: 50%;
			min-height: 4px;
			height: 4px;
			background-color: #523563;
		}

		.bills-wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.bill-container {
				display: flex;
				gap: 16px;
				align-items: center;

				.bill-select {
					width: 12px;
					height: 12px;
					border: 1px solid #d8d8d8;
					border-radius: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;

					&.selected {
						border-color: #46be46;
					}

					&.disabled {
						visibility: hidden;
					}
				}

				.select-btn {
					cursor: pointer;
					padding: 4px 0;
					font-size: 1em;
					margin-top: 2px;

					&:hover {
						color: #3498DB;
					}
				}

				.bill-container-inner {
					flex: 1;
					cursor: pointer;
					background-color: #f8f8fc;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
					display: flex;
					justify-content: space-between;
					padding: 8px 12px;

					&:hover {
						background-color: #f1f1fc;
					}

					.bill-details {
						display: flex;
						flex-direction: column;
						gap: 4px;
						justify-content: flex-end;
						font-size: 1em;
						color: #272727;

						.bill-client {
							font-size: 1em;
							font-weight: 600;
							color: #272727;
						}

						.bill-status {
							align-self: flex-start;
							padding: 2px 8px;
							border-radius: 4px;
							font-size: 0.9em;
							text-align: center;

							&.credited {
								background-color: #d35a4722;
								color: #d35a47;
								border: 0.25px solid #d35a4744;
							}

							&.bill {
								background-color: #3498DB22;
								color: #3498DB;
								border: 0.25px solid #3498DB44;
							}
						}
					}
				}
			}
		}
	}
}