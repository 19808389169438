.user-modal-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-size: 12px;

	.modal-body {
		overflow: auto;
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.user-id {
			font-size: 1em;
			color: #9d9d9d;
			font-weight: 600;
			align-self: flex-end;
		}

		.user-img-container {
			align-self: center;
			width: 100px;
			height: 100px;
			min-height: 100px;
			border-radius: 50%;
			border: 1px solid #d8d8d8;
			position: relative;
			color: #9d9d9d;

			&.editable {
				cursor: pointer;
				transition: all 250ms ease-in-out;

				&:hover {
					box-shadow: 0px 0px 8px #bbbbbb;
					transform: scale(1.05);
				}
			}

			.item_img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					aspect-ratio: 1/1;
					object-fit: cover;
				}
			}

			.user-img-action {
				position: absolute;
				bottom: 4px;
				right: 8px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid #f8f8fc;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				font-weight: 900;
				color: #f8f8fc;
				background-color: #3498db;

				&.active {
					background-color: #46be46;
					font-size: 12px;
				}

				&.inactive {
					background-color: #b53e2c;
					font-size: 10px;
				}
			}
		}

		.section-header-row {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.section-header-btn {
				font-size: 0.9em;
				color: #9d9d9d;
				cursor: pointer;
				text-decoration: underline;
				transition: all 200ms ease-in-out;

				&:hover {
					color: #3498db;
				}
			}
		}

		.section-header {
			font-size: 1.2em;
			font-weight: 600;
			color: #272727;
		}

		.divider {
			width: 50%;
			min-height: 4px;
			height: 4px;
			background-color: #523563;
			margin: 0 16px;
		}

		.user-info-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 16px;
			border-radius: 8px;

			.input-row-wrapper {
				display: flex;
				justify-content: space-between;
				gap: 16px;
				flex-wrap: wrap;
			}

			.input-wrapper {
				flex: 1;
				display: flex;
				flex-direction: column;

				.input-label {
					min-width: 100px;
					font-size: 1em;
					color: #9d9d9d;
					margin-bottom: 4px;
				}

				.validation-error {
					color: #d35a47;
					font-size: 0.85em;
				}

				.input-box-wrapper {
					width: 100%;
					font-size: 1em;
					border: 0;
					outline: 0;
					border: 1px solid #d8d8d844;
					background-color: #f1f1fc;
					height: 32px;
					border-radius: 4px;
					padding: 0 8px;
					color: #272727;
					display: flex;
					align-items: center;

					&:disabled,
					&.disabled {
						background-color: transparent;

						&::placeholder {
							opacity: 0;
						}
					}
				}
			}
		}

		.user-bills-container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 16px;
			border-radius: 8px;

			.bills-table-container {
				flex: 1;
				flex-direction: column;
				border-bottom: 1px solid #ededf0;

				.table-grid-container {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					padding: 12px 6px;
					column-gap: 8px;

					&.header {
						border-top: 1px solid #ededf0;
						border-bottom: 1px solid #ededf0;
						background-color: #ededf0;
						margin-bottom: 8px;
					}

					.table-item-text {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 4px;
						color: #272727;
						font-size: 0.9em;
						white-space: nowrap;

						&.copy {
							cursor: copy;
						}

						&.align-right {
							align-items: flex-end;
							align-self: center;
						}

						&.align-left {
							align-items: flex-start;
						}

						&>span {
							color: #9d9d9d;
						}

						.bill-price {
							font-size: 1.1em;
							color: #46be46;
							font-weight: 600;

							&.void {
								color: #d35a47;
							}

							&>b {
								font-size: 0.8em;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1580px) {
	.user-modal-container {
		font-size: 11px;
	}
}

@media screen and (max-width: 1290px) {
	.user-modal-container {
		font-size: 10px;
	}
}

@media screen and (max-width: 800px) {
	.user-modal-container {
		font-size: 9px;
	}
}