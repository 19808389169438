#spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	animation: spin 1s linear infinite;
	color: #e7e7e7;
	font-size: 1em;
	margin-bottom: 2px;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}