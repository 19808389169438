.activity-item-container {
	display: flex;
	gap: 16px;

	.activity-separator-container {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.activity-bullet {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			border: 3px solid #d8d8d888;
		}

		.activity-separator {
			align-self: flex-start;
			width: 3px;
			border-radius: 4px;
			flex: 1;
			background-color: #d8d8d844;
			margin-left: 5px;
		}

	}

	.activity-details-container {
		flex: 1;
		display: flex;
		gap: 24px;
		flex-wrap: wrap;

		.activity-timing {
			font-size: 1.1em;
			color: #523563;
			font-weight: 600;
			margin-top: 2px;
			min-width: 124px;
		}

		.activity-info-container {
			flex: 1;
			min-width: 300px;
			display: flex;
			gap: 16px;
			padding-bottom: 24px;
			border-bottom: 1px solid #d8d8d844;

			.activity-user {
				width: 36px;
				min-width: 36px;
				height: 36px;
				border-radius: 50%;
				border: 1px solid #d8d8d844;
				color: #9d9d9d99;

				.user-img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
						aspect-ratio: 1/1;
						object-fit: cover;
					}
				}
			}

			.activity-details {
				display: flex;
				flex-direction: column;
				gap: 8px;
				margin-top: 4px;

				.activity-flag {
					display: flex;
					gap: 8px;
					align-items: center;
					font-size: 0.9em;
					font-weight: 400;
					text-transform: capitalize;

					i {
						margin-bottom: 2px;
					}
				}

				.activity-message {
					font-size: 1.1em;
					font-weight: 400;
					color: #272727;
					line-height: 1.2em;

					span {
						font-weight: 600;
					}
				}

				.activity-time {
					font-size: 0.9em;
					font-weight: 400;
					color: #9d9d9d;
				}
			}
		}
	}
}